import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class NewService {
  constructor(private commonService: CommonService) { }

  getAll(langId: string = '',pageIndex: number = Pagination.PageIndex, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`new`, false, pageSize, pageIndex, langId);
  }

  getById(id: number): Observable<ResponseResult> {
    return this.commonService.getById(`new/${id}/detail`);
  }

  create(model): Observable<ResponseResult> {
    return this.commonService.createData(`new`, model, model.langId, model.parentId);
  }

  update(model): Observable<ResponseResult> {
    return this.commonService.updateData(`new/${model.newId}`, model);
  }

  delete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`new/${id}`);
  }
}
