import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class AddressService {
  constructor(private commonService: CommonService) { }

  getCountrys(lang: string = '', pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`address/countrys`, false, pageSize, pageIndex, lang);
  }

  getProvinces(id: number, lang: string = '', pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`address/countrys/${id}/provinces`, false, pageSize, pageIndex, lang);
  }

  getDistricts(id: number, lang: string = '', pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`address/provinces/${id}/districts`, false, pageSize, pageIndex, lang);
  }

  getWards(id: number, lang: string = '', pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`address/districts/${id}/wards`, false, pageSize, pageIndex, lang);
  }

}
