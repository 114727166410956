import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class UserGroupService {
  constructor(private commonService: CommonService) { }

  getAll(pageIndex: number = Pagination.PageIndex, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`user-group`, false, pageSize, pageIndex);
  }

  create(model): Observable<ResponseResult> {
    return this.commonService.createData(`user-group`, model);
  }

  update(model): Observable<ResponseResult> {
    return this.commonService.updateData(`user-group/${model.userGroupId}`, model);
  }

  delete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`user-group/${id}`);
  }
}
