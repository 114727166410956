import { Component, Input} from '@angular/core';
import { navItems } from 'src/app/_nav';

@Component({
  selector: 'app-sidebar',
  template: `
      <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" nzWidth="200px">
      <div class="logo"></div>
      <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
          <ng-template ngFor let-navitem [ngForOf]="navDatas">
              <app-sidebar-dropdown *ngIf="isChildren(navitem)" [navItem] ="navitem"></app-sidebar-dropdown>
              <app-sidebar-item *ngIf="!isChildren(navitem)" [navItem] ="navitem"></app-sidebar-item>
          </ng-template>
      </ul>
    </nz-sider>
  `
})
export class AppSidebarComponent {
  isCollapsed = false;
  public navDatas = navItems;

  constructor() { }

  public isChildren(item) {
    return item.children ? true : false;
  }

}

@Component({
  selector: 'app-sidebar-dropdown',
  template: `
    <li nz-submenu nzTitle="{{navItem.name}}" nzIcon="{{navItem.icon}}">
      <ul>
          <ng-template  ngFor let-item [ngForOf]="navItem.children">
              <app-sidebar-item [navItem] ="item"></app-sidebar-item>
          </ng-template>
      </ul>
    </li>
  `
})
export class AppSidebarDropDownComponent {
  @Input() navItem: any;

  constructor() { }
}


@Component({
  selector: 'app-sidebar-item',
  template: `
    <li nz-menu-item>
        <i nz-icon nzType="{{navItem.icon}}"></i>
        <span>{{navItem.name}}</span>
    </li>
  `
})
export class AppSidebarItemComponent {
  @Input() navItem: any;

  constructor() { }
}

export const APP_SIDEBAR_NAV = [
  AppSidebarComponent,
  AppSidebarDropDownComponent,
  AppSidebarItemComponent,
];
