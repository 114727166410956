import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateHelpersService, GlobalsService } from 'src/app/shares/helpers';
import { AuthService } from 'src/app/shares/services';
import { Md5 } from 'ts-md5/dist/md5';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { Lang } from 'src/app/shares/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup;
  lang: any;

  constructor(private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private globals: GlobalsService,
    private message: NzMessageService,
    private translate: TranslateHelpersService) {
    this.lang = {
      data: Lang,
      id: this.translate.getLocalLang()
    };
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.router.navigateByUrl('dashboard');
    }
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }


  submitForm(): void {
    if (!this.formGroup.valid) return;
    const data = { ...this.formGroup.value };
    data.password = Md5.hashStr(data.password);
    this.globals.showLoading();
    this.authService.login(data.userName, data.password).subscribe(res => {
      if (res) {
        this.authService.getPermissions().subscribe(res => {
          if (res)
            this.router.navigateByUrl('dashboard');
          else
            this.message.create('error', 'Tên đăng nhập không có quyền truy cập vào hệ thông.');
        });
      } else {
        this.message.create('error', 'Tên đăng nhập hoặc mật khẩu không đúng.');
      }
      this.globals.hideLoading();
    });
  }

  setDefaultLang(lang: string) {
    this.translate.defaultLang(lang);
  }
}
