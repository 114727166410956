import { navItems, navHeaderLeft, navHeaderUser } from 'src/app/_nav';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateHelpersService, GlobalsService } from 'src/app/shares/helpers';
import { Local, Lang, StatusCode, ImageType } from 'src/app/shares/constants';
import { AuthService, UserService } from 'src/app/shares/services';
import { Router } from '@angular/router';
import { isFulfilled } from 'q';
import { UserDataDetailComponent } from 'src/app/pages/users/user-datas/user-data-detail/user-data-detail.component';
import { ResponseResult } from 'src/app/shares/interfaces';

@Component({
  selector: 'app-defaut-layout',
  templateUrl: './defaut-layout.component.html',
  styleUrls: ['./defaut-layout.component.scss']
})
export class DefautLayoutComponent implements OnInit {
  isCollapsed = false;
  navDatas = navItems;
  navHeadeLeft = navHeaderLeft;
  navHeadUser = navHeaderUser;
  fullName: string = localStorage.getItem(Local.FullName);
  lang: any;
  userInfo: any;

  configDetail: any = {
    visible: false,
    model: null
  };

  @ViewChild('appdetail', { static: false }) appdetail: UserDataDetailComponent;

  constructor(private translate: TranslateHelpersService,
    private router: Router,
    private userService: UserService,
    private globalsService: GlobalsService,
    private globals: GlobalsService,
    private authService: AuthService) {
    this.lang = {
      data: Lang,
      id: this.translate.getLocalLang()
    };
    this.userInfo = this.globals.getUserInfo()
  }

  ngOnInit() {
  }

  eventAction($event) {
  }

  checkPermission(url){
    if(url === ''){
      return true
    }
    return this.authService.checkPermissonMenu('child', url);
  }

  goProfile(){
    const user = this.globalsService.getUserInfo();
    if (user != null) {
      this.getUser(user.userId);
    }
  }

  getUser(id: number) {
    this.globals.showLoading();
    this.userService.getById(id).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.configDetail.visible = true;
        this.appdetail.editData(res.data, false);
      } else {
        this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  getAvatarUser() {
    const user = this.globalsService.getUserInfo();
    if (user != null) {
      if (user.avatar != null) {
        return (ImageType + user.avatar);
      }
    }
    return 'assets/img/boy.png';
  }

  isChildren(item) {
    if(item.children && this.authService.checkPermissonMenu('par', item)){
      return true;
    }
    return false;
  }

  setDefaultLang(lang: string) {
    this.translate.defaultLang(lang);
    window.location.reload();
  }

  isDefaultLang() {
    return this.translate.getLocalLang();
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('login');
  }

  getImage(name: string) {
    return 'assets/icons/' + name;
  }

}
