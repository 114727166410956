import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigTableInterface, ResponseResult } from 'src/app/shares/interfaces';
import { Action, StatusCode, Pagination } from 'src/app/shares/constants';
import { TableComponent } from 'src/app/components';
import { UserDataDetailComponent } from '../user-data-detail/user-data-detail.component';
import { NzModalService } from 'ng-zorro-antd';
import { UserGroupService } from 'src/app/shares/services/user-group.service';
import { TranslateHelpersService, GlobalsService } from 'src/app/shares/helpers';
import { UserService } from 'src/app/shares/services/user.service';

@Component({
  selector: 'app-user-data-list',
  templateUrl: './user-data-list.component.html',
  styleUrls: ['./user-data-list.component.scss']
})
export class UserDataListComponent implements OnInit {
  dataFilter: any;
  configTable: ConfigTableInterface = {
    Data: [],
    ColWidth: ['150px', '200px', '200px', '200px', '200px', '200px', '400px', '200px'],
    ActionHeader: [
      { Key: Action.Create, Name: 'Globals.create', Icon: '' },
    ],
    Action: [
      { Key: Action.Edit, Name: 'Edit', Icon: 'edit' },
      { Key: Action.Delete, Name: 'Delete', Icon: 'delete' },
      { Key: Action.Translate, Name: 'Translate', Icon: 'transaction' },
    ],
    Header: [
      { Key: 'userCode', Name: 'User.usercode' },
      { Key: 'fullName', Name: 'User.fullName' },
      { Key: 'address', Name: 'User.address' },
      { Key: 'phoneNumber', Name: 'User.phonenumber' },
      { Key: 'email', Name: 'User.email' },
      { Key: 'userName', Name: 'User.username' },
    ]
  };

  configDetail: any = {
    visible: false,
    model: null
  };

  @ViewChild('appiontable', { static: false }) appiontable: TableComponent;
  @ViewChild('appdetail', { static: false }) appdetail: UserDataDetailComponent;

  constructor(private modalService: NzModalService,
    private userGroupService: UserGroupService,
    private userService: UserService,
    private translateHelpersService: TranslateHelpersService,
    private globals: GlobalsService) {
    this.configTable.Data = [];
    this.dataFilter = {};
  }

  ngOnInit() {
    this.getUserGroups();
  }

  getUserGroups() {
    this.globals.showLoading();
    this.userGroupService.getAll().subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataFilter.data = res.data.items;
        this.dataFilter.value = res.data.items[0].userGroupId;
        this.getUser(this.dataFilter.value);
      } else {
        // this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  getUser(id: number, pageIndex: number = Pagination.PageIndex) {
    this.globals.showLoading();
    this.userService.getByUserGroupId(id, pageIndex).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.appiontable.setData(res.data.items, res.data.totalItems);
      } else {
        this.appiontable.setData([], 0);
        // this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  actionClicked($event) {
    if ($event.action === Action.PageChange) {
      this.getUser(this.dataFilter.value, $event.data);
    }
    if ($event.action === Action.Create) {
      this.configDetail.visible = true;
      this.appdetail.editData(null,true,this.dataFilter.value,0);
    }
    if ($event.action === Action.Translate) {
      this.configDetail.visible = true;
      this.appdetail.editData($event.data, true);
    }
    if ($event.action === Action.Edit) {
      this.configDetail.visible = true;
      this.appdetail.editData($event.data);
    }
    else if ($event.action === Action.Delete) {
      const ref = this.modalService.confirm({
        nzTitle: this.translateHelpersService.translate('Globals.confirm'),
        nzContent: this.translateHelpersService.translate('Globals.confirm-delete'),
        nzOkText: this.translateHelpersService.translate('Globals.ok'),
        nzOnOk: (res) => {
          this.globals.showLoading();
          this.userService.delete($event.data.userId).subscribe((res) => {
            this.globals.hideLoading();
            if (res.statusCode === StatusCode.Status200OK) {
              this.globals.saveSuccess();
              this.getUser(this.dataFilter.value);
            } else {
              this.globals.getErrorMessage(res.message);
            }
          }, (error) => {
            this.globals.hideLoading();
            this.globals.saveError();
          });
        },
        nzCancelText: this.translateHelpersService.translate('Globals.cancel'),
      });

    }
  }

  filterData($event) {
    if ($event != null) {
      this.getUser($event);
    }
  }

  eventAction($event) {
    this.getUser(this.dataFilter.value);
  }

}
