export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  image?: string;
  divider?: boolean;
  children?: NavData[];
}

export const navItems: NavData[] = [
  {
    name: "siderbar.dashboard",
    url: "/dashboard",
    icon: "",
    image: "icondashboard.svg",
  },
  {
    name: "siderbar.mapinfo",
    url: "/info",
    icon: "",
    image: "worldwide.svg",
  },
  {
    name: "siderbar.associations",
    url: "",
    icon: "star",
    image: "iconhiephoi.svg",
    children: [
      {
        name: "siderbar.associations",
        url: "/associations",
        icon: "",
      },
    ],
  },
  {
    name: "siderbar.suppliers",
    url: "",
    icon: "star",
    image: "leadership.svg",
    children: [
      {
        name: "siderbar.suppliers",
        url: "/suppliers",
        icon: "",
      },
    ],
  },
  {
    name: "siderbar.farmers",
    url: "",
    icon: "star",
    image: "farmers.svg",
    children: [
      {
        name: "siderbar.farmers",
        url: "/farmers",
        icon: "",
      }
    ],
  },
  {
    name: "siderbar.process",
    url: "",
    icon: "star",
    image: "process.svg",
    children: [
      {
        name: "siderbar.process",
        url: "/process/flows",
        icon: "",
      },
      {
        name: "siderbar.cate",
        url: "/cates/list",
        icon: "",
      },
    ],
  },
  {
    name: "siderbar.report-field",
    url: "",
    icon: "star",
    image: "database.svg",
    children: [
      // {
      //   name: 'siderbar.fields',
      //   url: '/report-fields/fields',
      //   icon: ''
      // },
      // {
      //   name: 'siderbar.user-fields',
      //   url: '/report-fields/user-fields',
      //   icon: ''
      // },
      {
        name: "siderbar.packages-list",
        url: "/packages/list",
        icon: "",
      }
      // {
      //   name: "siderbar.package-details",
      //   url: "/packages/details",
      //   icon: "",
      // },
    ],
  },
  {
    name: "siderbar.products",
    url: "",
    icon: "star",
    image: "coffee-breaks.svg",
    children: [
      // {
      //   name: "siderbar.price-products",
      //   url: "/products/prices",
      //   icon: "",
      // },
      {
        name: "siderbar.product-curde",
        url: "/products/product-curde",
        icon: "",
      },
      {
        name: "siderbar.package-infos",
        url: "/products/package-infos",
        icon: "",
      },
      {
        name: "siderbar.product",
        url: "/products/products",
        icon: "",
      },
    ],
  },
  // {
  //   name: 'siderbar.homenests',
  //   url: '',
  //   icon: 'star',
  //   image:'iconhomenest.svg',
  //   children: [
  //     {
  //       name: 'siderbar.homenests',
  //       url: '/homenests',
  //       icon: ''
  //     },
  //     {
  //       name: 'siderbar.homenest-map',
  //       url: '/homenests/map',
  //       icon: ''
  //     }
  //   ]
  // },
  {
    name: "siderbar.user-datas",
    url: "",
    icon: "star",
    image: "iconaccount.svg",
    children: [
      {
        name: "siderbar.user-groups",
        url: "/users/user-groups",
        icon: "",
      },
      {
        name: "siderbar.user-datas",
        url: "/users",
        icon: "",
      },
      // {
      //   name: "siderbar.user-roles",
      //   url: "/users/user-roles",
      //   icon: "",
      // },
    ],
  },
  // {
  //   name: 'siderbar.verified',
  //   url: '',
  //   icon: 'star',
  //   image:'verified-text-paper.svg',
  //   children: [
  //     {
  //       name: 'siderbar.verifieds',
  //       url: '/verifieds',
  //       icon: ''
  //     },
  //   ]
  // },
  // {
  //   name: "siderbar.system",
  //   url: "",
  //   icon: "star",
  //   image: "iconsys.svg",
  //   children: [
  //     {
  //       name: "siderbar.modules",
  //       url: "/role-rights/modules",
  //       icon: "",
  //     },
  //     {
  //       name: "siderbar.roles",
  //       url: "/role-rights/roles",
  //       icon: "",
  //     },
  //     {
  //       name: "siderbar.right-groups",
  //       url: "/role-rights/right-groups",
  //       icon: "",
  //     },
  //     {
  //       name: "siderbar.rights",
  //       url: "/role-rights/rights",
  //       icon: "",
  //     },
  //     {
  //       name: "siderbar.rolerights",
  //       url: "/role-rights/rolerights",
  //       icon: "",
  //     },
  //   ],
  // },
  {
    name: "siderbar.other",
    url: "",
    icon: "star",
    image: "portfolio.svg",
    children: [
      {
        name: "siderbar.notifications",
        url: "/notifications",
        icon: "",
      },
      {
        name: "siderbar.news",
        url: "/news",
        icon: "",
      },
      {
        name: "siderbar.fertilizer-medicines",
        url: "/fertilizer-medicines",
        icon: "",
      },
    ],
  },
];

export const navHeaderLeft: NavData[] = [
  {
    name: "headerleft.menu1",
    url: "",
    icon: "",
  },
  {
    name: "headerleft.menu2",
    url: "",
    icon: "",
  },
  {
    name: "headerleft.menu3",
    url: "",
    icon: "",
  },
];

export const navHeaderUser: NavData[] = [
  {
    name: "headeruser.systems",
    url: "",
    icon: "",
  },
  {
    name: "headeruser.profile",
    url: "",
    icon: "",
  },
  {
    divider: true,
  },
  {
    name: "headeruser.logout",
    url: "",
    icon: "",
  },
];
