import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class ModuleService {
  constructor(private commonService: CommonService) { }

  getAll(pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`module`, false, pageSize, pageIndex);
  }

  create(model): Observable<ResponseResult> {
    return this.commonService.createData(`module`, model);
  }

  update(model): Observable<ResponseResult> {
    return this.commonService.updateData(`module/${model.moduleId}`, model);
  }

  delete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`module/${id}`);
  }
}
