import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ImageType } from 'src/app/shares/constants';
import { GlobalsService } from 'src/app/shares/helpers';
import { AuthService } from 'src/app/shares/services';

@Component({
  selector: 'app-detail-farmer',
  templateUrl: './detail-farmer.component.html',
  styleUrls: ['./detail-farmer.component.scss']
})
export class DetailFarmerComponent implements OnInit {
  @Output() actionClicked = new EventEmitter<any>();
  @Input() DataDetail: any;
  isAuthenticated: boolean = false;
  constructor( private globals: GlobalsService,
    private authService: AuthService
    ) {
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  ngOnInit() {
  }

  goClose(){
    this.actionClicked.emit({
      action: "Close",
      data: null
    });
  }

  goShowTerrai(){
    this.actionClicked.emit({
      action: "TTTT",
      data: null
    });
  }

  getTotaFarm(listFarm, type){
    let total = 0;
    if(listFarm){
      listFarm.forEach(element => {
        if(type === 'Area')
          total = total + Number(element.acreage);
        if(type === 'Quantity')
          total = total + Number(element.quantity);
      });
    }
    return total;
  }

  getImage(detail){
    return this.globals.getAvartaUser(detail.userId);
  }
}
