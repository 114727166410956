import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';
import { CommonService } from './common.service';
import { StatusCode, Local } from '../constants';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private commonService: CommonService,
              public jwtHelper: JwtHelperService,
              private companyService: CompanyService,
              private router: Router) { }

  login(userName: string, passWord: string): Observable<boolean> {
    return this.commonService.createData('auth/login', { username: userName, pws: passWord }).pipe(
      map((res: ResponseResult) => {
        if (res.statusCode === StatusCode.Status200OK) {
          localStorage.setItem(Local.TOKEN, res.data.accessToken);
          localStorage.setItem(Local.User, JSON.stringify(res.data.user));
          localStorage.setItem(Local.FullName, res.data.user.fullName);
          return true;
        }
        return false;
      }));
  }

  getPermissions(): Observable<boolean> {
    return this.commonService.getById(`auth/permissions/website`).pipe(
      map((res: ResponseResult) => {
        if (res.statusCode === StatusCode.Status200OK) {
        localStorage.setItem(Local.Permission, JSON.stringify(res.data));
          return true;
        }
        else {
          this.logout();
        }
        return false;
      }));
  }

  checkPermissonMenu(type, item) {
    const permission = localStorage.getItem(Local.Permission);
    if (typeof permission != undefined && permission != '') {
      let per = JSON.parse(permission);
      if (type === 'par') {
        return this.checkPermissionParent(per, item);
      } else {
        return this.checkPermissionChild(per, item);
      }
    }
    return false;
  }

  checkPermissionParent(per, item) {
    let flag = false;
    item.children.forEach(element => {
      if (this.checkPermissionChild(per, element.url) === true) {
        flag = true;
      }
    });
    return flag;
  }

  checkPermissionChild(per, item) {
    let flag = false;
    per.forEach(element => {
      if (item.includes(element.group)) {
        flag = true;
      }
    });
    return flag;
  }

  checkPermissionAction(actionName: string): boolean {
    const snapshot = this.router.routerState.snapshot;
    const permission = this.getPermission();
    const group = permission.filter((val) => {
      return snapshot.url.includes(val.group);
    });
    if (group.length > 0) {
      const rights = group.filter((item) => {
        return item.rights.indexOf(actionName) > -1;
      });
      if (rights.length > 0){
        return true;
      }
    }
    return false;
  }

  logout(): boolean {
    localStorage.removeItem(Local.TOKEN);
    localStorage.removeItem(Local.FullName);
    localStorage.removeItem(Local.User);
    localStorage.removeItem(Local.Permission);
    return true;
  }

  public isAuthenticated(): boolean {
    // const companyInfo = this.companyService.getById();
    const token = localStorage.getItem(Local.TOKEN);
    const tokenInfo = this.jwtHelper.decodeToken(token);
    if (tokenInfo) {
      this.companyService.getById(tokenInfo.ComId).subscribe((res: ResponseResult) => {
        if (res.statusCode === StatusCode.Status200OK) {
          if (res.data.isActive === false) {
            this.logout();
            this.router.navigate(['login']);
          }
        }
      });
    }
    return !this.jwtHelper.isTokenExpired(token);
  }

  getPermission() {
    const permission = localStorage.getItem(Local.Permission);
    if (typeof permission != undefined && permission != '') {
      let per = JSON.parse(permission);
      return per;
    }
    return [];
  }

  geturlCheck(): string {
    return this.commonService.base_url + 'auth/check';
  }
}
