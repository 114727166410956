import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserDataListComponent } from './user-datas/user-data-list/user-data-list.component';
import { UserRoleListComponent } from './user-roles/user-role-list/user-role-list.component';
import { UserGroupListComponent } from './user-groups/user-group-list/user-group-list.component';


const routes: Routes = [
  {
    path: '',
    component: UserDataListComponent,
    data: {
      breadcrumb: 'Users'
    },
  },
  {
    path: 'user-roles',
    component: UserRoleListComponent,
    data: {
      breadcrumb: 'User Roles'
    },
  },
  {
    path: 'user-groups',
    component: UserGroupListComponent,
    data: {
      breadcrumb: 'User Groups'
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
