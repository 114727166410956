import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class ProcessService {
  constructor(private commonService: CommonService) { }

  processFlowGetAll(pageIndex: number = Pagination.PageIndex,innal: boolean = false, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`process/flows?innal=${innal}`, true, pageSize, pageIndex);
  }

  processFlowGetById(id): Observable<ResponseResult> {
    return this.commonService.getById(`process/flows/${id}/detail`);
  }
  
  processFlowCreate(model): Observable<ResponseResult> {
    return this.commonService.createData(`process/flows`, model);
  }

  processFlowAddStep(model): Observable<ResponseResult> {
    return this.commonService.createData(`process/flow-steps/steps`, model);
  }

  processFlowUpdate(model): Observable<ResponseResult> {
    return this.commonService.updateData(`process/flows/${model.id}`, model);
  }

  processFlowDelete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`process/flows/${id}`);
  }

  processStepAddBoard(id): Observable<ResponseResult> {
    return this.commonService.getById(`process/flows/add-step/${id}`);
  }
  
  //
  processStepGetAll(pageIndex: number = Pagination.PageIndex,pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`process/steps`, false, pageSize, pageIndex);
  }

  processStepGetById(id): Observable<ResponseResult> {
    return this.commonService.getById(`process/steps/${id}/detail`);
  }

  processStepCreate(model): Observable<ResponseResult> {
    return this.commonService.createData(`process/steps`, model);
  }

  processStepUpdate(model): Observable<ResponseResult> {
    return this.commonService.updateData(`process/steps/${model.id}`, model);
  }

  processStepDelete(model): Observable<ResponseResult> {
    return this.commonService.updateData(`process/process-steps/${model.stepId}`, model);
  }

  //
  processFlowStepGetAll(pageIndex: number = Pagination.PageIndex, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`process/flow-steps`, false, pageSize, pageIndex);
  }
  
  processFlowStepGetById(id): Observable<ResponseResult> {
    return this.commonService.getById(`process/flow-steps/${id}/detail`);
  }

  processFlowStepGetByFlowId(id): Observable<ResponseResult> {
    return this.commonService.getById(`process/flow-steps/${id}/flow`);
  }

  processFlowStepCreate(model): Observable<ResponseResult> {
    return this.commonService.createData(`process/flow-steps`, model);
  }

  processFlowStepUpdate(model): Observable<ResponseResult> {
    return this.commonService.updateData(`process/flow-steps/${model.id}`, model);
  }

  processFlowStepDelete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`process/flow-steps/${id}`);
  }

   //
  processResultGetAll(pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`process/results`, false, pageSize, pageIndex);
  }

  processResultGetById(id): Observable<ResponseResult> {
    return this.commonService.getById(`process/results/${id}/detail`);
  }

  processResultCreate(model): Observable<ResponseResult> {
    return this.commonService.createData(`process/results`, model);
  }

  processResultUpdate(model): Observable<ResponseResult> {
    return this.commonService.updateData(`process/results/${model.id}`, model);
  }

  processResultUpdateCurStep(model): Observable<ResponseResult> {
    return this.commonService.updateData(`process/results/${model.id}/steps`, model);
  }

  processResultUpdateDone(model): Observable<ResponseResult> {
    return this.commonService.updateData(`process/results/${model.id}/done`, model);
  }

  processResultDelete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`process/results/${id}`);
  }

   //
  processResultStepGetAll(pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`process/result-steps`, false, pageSize, pageIndex);
  }

  processResultStepGetAllBystepId(productId: number, stepId: number): Observable<ResponseResult> {
    return this.commonService.getById(`process/result-steps/diary?productId=${productId}&stepId=${stepId}`);
  }

  processResultStepGetById(id): Observable<ResponseResult> {
    return this.commonService.getById(`process/result-steps/${id}/detail`);
  }

  processResultStepCreate(model): Observable<ResponseResult> {
    return this.commonService.createData(`process/result-steps`, model);
  }

  processResultStepUpdate(model): Observable<ResponseResult> {
    return this.commonService.updateData(`process/result-steps/${model.id}`, model);
  }

  processResultStepDelete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`process/result-steps/${id}`);
  }
}
