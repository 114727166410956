import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/shares/services/user.service';
import { UserGroupService } from 'src/app/shares/services/user-group.service';
import { GlobalsService, TranslateHelpersService } from 'src/app/shares/helpers';
import { ResponseResult } from 'src/app/shares/interfaces';
import { StatusCode, Action, Lang, ImageType } from 'src/app/shares/constants';
import { AddressService } from 'src/app/shares/services';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'app-user-data-detail',
  templateUrl: './user-data-detail.component.html',
  styleUrls: ['./user-data-detail.component.scss']
})
export class UserDataDetailComponent implements OnInit {

  formGroup: FormGroup;
  isShowForm: boolean = false;
  type: string = '';
  model: any;
  dataFilter: any;
  password: string = '';
  configImage: any = {colIn: 12, colIm: 12};
  //translate
  hideLeader: boolean;
  lang: any;

  @Input() configDetail: any;
  @Output() eventAction = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
    private userService: UserService,
    private addressService: AddressService,
    private userGroupService: UserGroupService,
    private translate: TranslateHelpersService,
    private globals: GlobalsService) {
    this.model = {};
    this.dataFilter = {};
    this.lang = Lang;
  }

  ngOnInit() {
    this.buildForm();
  }

  editData(data, hideLeader: boolean = false, groupId: number = 0, leaderId: number = 0) {
    this.hideLeader = !hideLeader;
    this.model = data;
    if (data == null) {
      this.model = {};
      if(leaderId > 0){
        this.model.leaderId = leaderId;
      }
      console.log(this.model.leaderId);
      this.type = Action.Create;
    } else {
      this.type = Action.Edit;
      this.getUserDetail();
    }
    console.log("Nhóm");
    if(groupId > 0){
      this.model.userGroupId = groupId;
      console.log(this.model.userGroupId);
    }

    this.isShowForm = false;
    this.dataFilter = {};
    this.model.countryId = 237;
    if(this.type == Action.Create){
      this.getProvinces(this.model.countryId);
    }
    this.getUserGroups();
    this.getUserLeaders();
    this.getUserImports();

    setTimeout(() => {
      this.buildForm();
      this.isShowForm = true;
    });
  }

  getUserDetail(){
    this.globals.showLoading();
    this.userService.getById(this.model.userId).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.model = res.data;
        const datadt = { ...this.model };
        this.password = datadt.passWord;
        this.model.image = ImageType + this.model.image;
        this.model.countryId = 237;
        this.getProvinces(this.model.countryId);
        this.buildForm();
        this.isShowForm = true;
      } else {
        this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  buildForm() {
    this.formGroup = this.fb.group({
      userCode: [this.model.userCode, [
        Validators.required
      ]],
      userName: [this.model.userName, [
      ]],
      fullName: [this.model.fullName, [
        Validators.required
      ]],
      // userGroupId: [this.model.userGroupId, [
      //   Validators.required
      // ]],
      leaderId: [this.model.leaderId, [
      ]],
      email: [this.model.email, [
      ]],
      address: [this.model.address, [
      ]],
      subDistrict: [this.model.subDistrict, [
      ]],
      danToc: [this.model.danToc, [
      ]],
      countryId: [this.model.countryId, [
      ]],
      provinceId: [this.model.provinceId, [
      ]],
      districtId: [this.model.districtId, []],
      wardId: [this.model.wardId, []],
      userImportId: [this.model.userImportId, []],
      phoneNumber: [this.model.phoneNumber, [
      ]],
      // description: [this.model.description, [
      // ]],
      // description1: [this.model.description1, [
      // ]],
      passWord: [this.model.passWord, []],
      nameMarriage: [this.model.nameMarriage, []],
      indentity: [this.model.indentity, []],
      note: [this.model.note, []],
    });
    
  }

  open(): void {
    this.configDetail.visible = true;
  }

  close(): void {
    this.configDetail.visible = false;
  }

  getResultImage($event){
    this.model.image = $event.image;
  }

  getUserGroups() {
    this.globals.showLoading();
    this.userGroupService.getAll().subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataFilter.userGroups = res.data.items;
      } else {
        this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  getUserLeaders() {
    this.globals.showLoading();
    this.userService.getByUserGroupId(11, 0).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataFilter.userLeaders = res.data.items;
        if (this.type === Action.Create && (!this.model.leaderId || this.model.leaderId == 0)) {
          this.model.leaderId = res.data.items[0].userId;
          this.formGroup.controls["leaderId"].setValue(res.data.items[0].userId);
        }
        setTimeout(() => {
          if (this.type === Action.Create && this.model.leaderId && this.model.leaderId > 0) {
            this.formGroup.controls["leaderId"].setValue(Number(this.model.leaderId));
          }
        }, 1);
       
      } else {
        this.dataFilter.userLeaders = [];
        // this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  getUserImports() {
    this.globals.showLoading();
    this.dataFilter.userImports = [];
    this.userService.getUserImport().subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataFilter.userImports = res.data;
      } else {
        this.dataFilter.userImports = [];
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  getCountrys(langId: string = '') {
    this.globals.showLoading();
    this.dataFilter.countrys = [];
    this.addressService.getCountrys(langId).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataFilter.countrys = res.data;
        if (this.model.countryId == null) {
          this.model.countryId = this.dataFilter.countrys[0].id;
        }
        this.getProvinces(this.model.countryId);
      } else {
        this.globals.hideLoading();
        this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  getProvinces(id: number, langId: string = '') {
    this.globals.showLoading();
    this.dataFilter.provinces = [];
    this.addressService.getProvinces(id, langId).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataFilter.provinces = res.data;
        if (this.model.provinceId == null) {
          this.model.provinceId = this.dataFilter.provinces[0].id;
        }
      } else {
        this.globals.getErrorMessage(res.message);
        this.globals.hideLoading();
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }
  

  getDistricts(id: number, langId: string = '') {
    this.dataFilter.districts = [];
    this.globals.showLoading();
    this.addressService.getDistricts(id, langId).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataFilter.districts = res.data;
        if (this.model.districtId == null) {
          this.model.districtId = this.dataFilter.districts[0].id;
        }
      } else {
        this.globals.hideLoading();
        this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.hideLoading();
      this.globals.getError();
    });
  }

  getWards(id: number, langId: string = '') {
    this.dataFilter.wards = [];
    this.globals.showLoading();
    this.addressService.getWards(id, langId).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataFilter.wards = res.data;
        if (this.model.wardId == null) {
          this.model.wardId = this.dataFilter.wards[0].id;
        }
      } else {
        this.globals.hideLoading();
        this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.hideLoading();
      this.globals.getError();
    });
  }

  changeLocation(type, $event) {
    if ($event != null) {
      switch (type) {
        // case 'country':
        //   this.model.provinceId = null;
        //   this.model.districtId = null;
        //   this.getProvinces($event, this.model.langId);
        //   break;
        case 'province':
          if($event != this.model.provinceId){
            this.model.districtId = null;
            this.model.wardId = null;
          }
          this.getDistricts($event, this.model.langId);
          break;
        case 'district':
          if($event != this.model.districtId){
            this.model.wardId = null;
          }
          this.getWards($event, this.model.langId);
          break;
      }
    }
  }

  submitForm() {
    if (!this.formGroup.valid) { return; }
    this.convertObject(this.formGroup.value);
    if(this.model.image){
      const Image = this.model.image.split('base64,');
      this.model.image = Image[1];
    }
    if (this.type === Action.Create) {
      const data = this.formGroup.value.passWord;
      this.model.passWord = Md5.hashStr(data);

      this.globals.showLoading();
      this.userService.create(this.model).subscribe((res) => {
        this.globals.hideLoading();
        if (res.statusCode === StatusCode.Status200OK) {
          this.globals.saveSuccess();
          this.eventAction.emit({
            event: Action.Create,
            success: true
          });
          this.close();
        } else {
          this.globals.getErrorMessage(res.message);
        }
      }, (error) => {
        this.globals.hideLoading();
        this.globals.saveError();
      });
    } else {
      this.globals.showLoading();
      const data = this.formGroup.value.passWord;
      if (this.password !== data) {
        this.model.passWord = Md5.hashStr(data);
      }
      this.userService.update(this.model).subscribe((res) => {
        this.globals.hideLoading();
        if (res.statusCode === StatusCode.Status200OK) {
          this.globals.saveSuccess();
          this.eventAction.emit({
            event: Action.Create,
            success: true
          });
          this.close();
        } else {
          this.globals.getErrorMessage(res.message);
        }
      }, (error) => {
        this.globals.hideLoading();
        this.globals.saveError();
      });
    }

  }

  convertObject(data) {
    Object.keys(data).forEach(e => {
      this.model[e] = data[e];
    });
  }

  setDefaultLang($event) {
    // this.getCountrys($event);
  }

  changeUserImport($event){
    let data = this.dataFilter.userImports.filter(c=> {return c.id == $event});
    if(data.length > 0){
      setTimeout(() => {
          let address = `${data[0].thon}, ${data[0].xa}, ${data[0].huyen}`;
          this.formGroup.controls["fullName"].setValue(data[0].chuHo);
          this.formGroup.controls["danToc"].setValue(data[0].danToc);
          this.formGroup.controls["subDistrict"].setValue(data[0].tieuKhu);
          this.formGroup.controls["address"].setValue(address);
          this.formGroup.controls["provinceId"].setValue(data[0].provinceId);
          this.formGroup.controls["districtId"].setValue(data[0].districtId);
          this.formGroup.controls["wardId"].setValue(data[0].wardId);
          this.formGroup.controls["userCode"].setValue(data[0].userCode);
      }, 1);
    }
  }

}
