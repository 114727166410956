import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserGroupService } from 'src/app/shares/services/user-group.service';
import { CompanyService } from 'src/app/shares/services';
import { GlobalsService } from 'src/app/shares/helpers';
import { ResponseResult } from 'src/app/shares/interfaces';
import { StatusCode, Action } from 'src/app/shares/constants';

@Component({
  selector: 'app-user-group-detail',
  templateUrl: './user-group-detail.component.html',
  styleUrls: ['./user-group-detail.component.scss']
})
export class UserGroupDetailComponent implements OnInit {
  formGroup: FormGroup;
  isShowForm: boolean = false;
  type: string = '';
  model: any;
  dataFilter: any;

  @Input() configDetail: any;
  @Output() eventAction = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
              private userGroupService: UserGroupService,
              private companyService: CompanyService,
              private globals: GlobalsService) {
    this.model = {};
    this.dataFilter = {};
  }

  ngOnInit() {
    this.buildForm();
  }

  editData(data) {
    this.model = data;
    if (data == null) {
      this.model = {};
      this.type = Action.Create;
    }

    this.isShowForm = false;
    this.dataFilter = {};

    this.getCompanys();

    setTimeout(() => {
      this.buildForm();
      this.isShowForm = true;
    });
  }

  buildForm() {
    this.formGroup = this.fb.group({
      name: [this.model.name, [
        Validators.required
      ]],
      description: [this.model.description, [
      ]],
      companyId: [this.model.companyId, [
        Validators.required
      ]],
    });
  }

  open(): void {
    this.configDetail.visible = true;
  }

  close(): void {
    this.configDetail.visible = false;
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.model.logo = myReader.result + '';
    };
    myReader.readAsDataURL(file);
  }

  getCompanys() {
    this.globals.showLoading();
    this.companyService.getAll().subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataFilter.companys = res.data.items;
      } else {
        this.globals.getError();
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  submitForm() {
    if (!this.formGroup.valid) { return; }
    this.convertObject(this.formGroup.value);

    if (this.type === Action.Create) {
      this.globals.showLoading();
      this.userGroupService.create(this.model).subscribe((res) => {
        this.globals.hideLoading();
        if (res.statusCode === StatusCode.Status200OK) {
          this.globals.saveSuccess();
          this.eventAction.emit({
            event: Action.Create,
            success: true
          });
          this.close();
        } else {
          this.globals.getErrorMessage(res.message);
        }
      }, (error) => {
        this.globals.hideLoading();
        this.globals.saveError();
      });
    } else {
      this.globals.showLoading();
      this.userGroupService.update(this.model).subscribe((res) => {
        this.globals.hideLoading();
        if (res.statusCode === StatusCode.Status200OK) {
          this.globals.saveSuccess();
          this.eventAction.emit({
            event: Action.Create,
            success: true
          });
          this.close();
        } else {
          this.globals.getErrorMessage(res.message);
        }
      }, (error) => {
        this.globals.hideLoading();
        this.globals.saveError();
      });
    }

  }

  convertObject(data) {
    Object.keys(data).forEach(e => {
      this.model[e] = data[e];
    });
  }

}
