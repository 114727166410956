import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { NgxCarousel } from 'ngx-carousel';
import { ResponseResult } from 'src/app/shares/interfaces';
import { UserService } from 'src/app/shares/services';
import { TranslateHelpersService, GlobalsService } from 'src/app/shares/helpers';
import { StatusCode, ImageType } from 'src/app/shares/constants';

@Component({
  selector: 'app-list-farmer',
  templateUrl: './list-farmer.component.html',
  styleUrls: ['./list-farmer.component.scss']
})
export class ListFarmerComponent implements OnInit {
  public carouselTileItems: Array<any>;
  public carouselTile: NgxCarousel;
  pageIndex: number = 1;
  @Input() DataInfo: any;
  @Output() actionClicked = new EventEmitter<any>();
  constructor(
    private userService: UserService,
    private translateHelpersService: TranslateHelpersService,
    private globals: GlobalsService
  ) { }

  ngOnInit() {
    this.carouselTile = {
      grid: {xs: 2, sm: 3, md: 5, lg: 9, all: 0},
      slide: 2,
      speed: 400,
      animation: 'lazy',
      point:{visible: false},
      load: 2,
      touch: true,
      easing: 'ease'
    }
    this.carouselTileItems = [];
    this.pageIndex = 1;
    this.getUser(2, this.pageIndex);
  }

  ngOnChanges(changes: SimpleChanges) {
    const detailChange : SimpleChange = changes.DataInfo;
    const previousValue = detailChange.previousValue;
    const currentValue = detailChange.currentValue;
    if(currentValue.leaderId && (!previousValue || !previousValue.leaderId  || (currentValue.leaderId != previousValue.leaderId))){
      this.getUser(2);
      this.carouselTileItems = [];
    }else{
      if(currentValue.leaderId === 0 && previousValue && currentValue.leaderId != previousValue.leaderId){
        this.getUser(2);
        this.carouselTileItems = [];
      }
    }
  }

  carouselTileLoad(evt: any) {
    this.pageIndex = this.pageIndex + 1;
    this.getUser(2, this.pageIndex);
  }

  goDetailFarmer(data){
    this.actionClicked.emit({
      action: "Detail",
      data: {...data}
    });
  }

  getUser(id: number, pageIndex = 1) {
    this.globals.showLoading();
    let leaderId = this.DataInfo.leaderId;
    if(!leaderId)
      leaderId = 0;

    this.userService.getByUserMaps(id, pageIndex,leaderId, 11).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.carouselTileItems = this.carouselTileItems.concat(res.data.items);
      } else {
        this.carouselTileItems = [];
        // this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  getImage(detail){
    return this.globals.getAvartaUser(detail.userId);
  }

  setDefaultPic(){
    return '../assets/img/farm.png'
  }
}
