import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor(private commonService: CommonService) { }

  getAll( pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`users`, false, pageSize, pageIndex);
  }

  getMemberRegister( pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`users/register`, false, pageSize, pageIndex);
  }

  getUserImport( pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`users/from-import`, false, pageSize, pageIndex);
  }
  
  getByUserGroupId(id: number,pageIndex: number = Pagination.PageIndex, leaderId: number = 0, provinceId: number = 0, districtId: number = 0, wardId: number = 0, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    if(!provinceId) provinceId = 0
    if(!districtId) districtId = 0
    if(!wardId) wardId = 0
    return this.commonService.getAll(`users/user-groups/${id}?leaderId=${leaderId}&provinceId=${provinceId}&districtId=${districtId}&wardId=${wardId}`, true, pageSize, pageIndex);
  }

  getByUserMaps(id: number,pageIndex: number = Pagination.PageIndex, leaderId: number = 0, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`users/user-maps/${id}?leaderId=${leaderId}`, true, pageSize, pageIndex);
  }

  getUserRoles(id: number): Observable<ResponseResult> {
    return this.commonService.getById(`users/user-groups/${id}/user-roles`);
  }

  getById(id: number): Observable<ResponseResult> {
    return this.commonService.getById(`users/${id}/detail`);
  }

  getAvatarById(id: number): Observable<ResponseResult> {
    return this.commonService.getById(`users/${id}/avatar`);
  }

  create(model): Observable<ResponseResult> {
    return this.commonService.createData(`users`, model, model.langId, model.parentId);
  }

  createRating(model): Observable<ResponseResult> {
    return this.commonService.createData(`users/rating`, model);
  }

  getRating(id: number): Observable<ResponseResult> {
    return this.commonService.getById(`users/${id}/rating`);
  }

  update(model): Observable<ResponseResult> {
    return this.commonService.updateData(`users/${model.userId}`, model);
  }

  updateUserRole(userId: number, model): Observable<ResponseResult> {
    return this.commonService.updateData(`users/user-role/${userId}`, model);
  }

  delete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`users/${id}`);
  }
}
