import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { IconsProviderModule } from "./icons-provider.module";
import { NgZorroAntdModule, NZ_I18N, vi_VN, NzPopoverModule, en_US } from "ng-zorro-antd";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  registerLocaleData,
  LocationStrategy,
  HashLocationStrategy,
  PathLocationStrategy
} from "@angular/common";
import vi from "@angular/common/locales/vi";
import en from "@angular/common/locales/en";
import { DefautLayoutComponent } from "./layouts";
import { APP_SIDEBAR_NAV, ComponentsModule, TableComponent } from "./components";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LoginComponent } from "./pages/login/login.component";
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { Local } from './shares/constants';
import { UsersModule } from './pages/users/users.module';
import { ReportTraceComponent } from './pages/report/report-trace/report-trace.component';
import { LayoutMapComponent } from './pages/map-info/layout-map/layout-map.component';
import { ListFarmerComponent } from './pages/map-info/list-farmer/list-farmer.component';
import { ListSupplierComponent } from './pages/map-info/list-supplier/list-supplier.component';
import { ListMapNewComponent } from './pages/map-info/list-map-new/list-map-new.component';
import { NgxCarouselModule } from 'ngx-carousel';
import { DetailFarmerComponent } from './pages/map-info/detail-farmer/detail-farmer.component';
import { DetailSuppierComponent } from './pages/map-info/detail-suppier/detail-suppier.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { ReportProductCurdeComponent } from "./pages/report/report-product-curde/report-product-curde.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/locales/", ".json");
}

registerLocaleData(vi);

const APP_CONTAINERS = [DefautLayoutComponent, 
  LayoutMapComponent, 
  ListFarmerComponent, 
  ListSupplierComponent, 
  ListMapNewComponent,
  DetailFarmerComponent,
  DetailSuppierComponent
];

const APP_COMPONENT = [APP_SIDEBAR_NAV];

export function funTokenGetter() {
  return localStorage.getItem(Local.TOKEN);
}

export function getLocalangauge() {
  return localStorage.getItem("DefaultLanguage") == "1" ? vi_VN : en_US;
}



@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ClickOutsideModule,
    ComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
          tokenGetter: funTokenGetter,
      }
    }),
    UsersModule,
    NgxCarouselModule,
    NzPopoverModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    ReportTraceComponent,
    ReportProductCurdeComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENT
  ],
  providers: [
    { provide: NZ_I18N, useValue: getLocalangauge() },
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
