import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class VerifiedHomeNestService {
  constructor(private commonService: CommonService) { }

  createUserVerified(model): Observable<ResponseResult> {
    return this.commonService.createData(`verified-homenest/user-verifieds`, model);
  }

  getAllUserVerified(pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`verified-homenest/user-verifieds`, false, pageSize, pageIndex);
  }

  getUserVerifiedByUserId(id: number, pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`verified-homenest/user-verifieds/${id}/homes`, false, pageSize, pageIndex);
  }

  updateUserVerified(model): Observable<ResponseResult> {
    return this.commonService.updateData(`verified-homenest/user-verifieds/${model.id}`, model);
  }

  deleteUserVerified(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`verified-homenest/user-verifieds/${id}`);
  }

  getAll(pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`verified-homenest`, false, pageSize, pageIndex);
  }

  getByHomenestId(id:number,pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`verified-homenest/homenest/${id}`, false, pageSize, pageIndex);
  }

  create(model): Observable<ResponseResult> {
    return this.commonService.createData(`verified-homenest`, model);
  }

  update(model): Observable<ResponseResult> {
    return this.commonService.updateData(`verified-homenest/${model.verifiedProductId}`, model);
  }

  delete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`verified-homenest/${id}`);
  }
}
