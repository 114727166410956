import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class HomeNestService {
  constructor(private commonService: CommonService) { }

  getAll(pageSize: number = Pagination.PageSize,
         pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`farm`, false, pageSize, pageIndex);
  }

  getFarmMap(pageSize: number = Pagination.PageSize,
    pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`farm?isMap=true`, true, pageSize, pageIndex);
  }


  getByMemberId(id: number,pageIndex: number = Pagination.PageIndex, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`farm/members/${id}`, false, pageSize, pageIndex);
  }

  getById(id: number): Observable<ResponseResult> {
    return this.commonService.getById(`farm/${id}/detail`);
  }

  create(model): Observable<ResponseResult> {
    return this.commonService.createData(`farm`, model, model.langId, model.parentId);
  }

  update(model): Observable<ResponseResult> {
    return this.commonService.updateData(`farm/${model.homeNestId}`, model);
  }

  delete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`farm/${id}`);
  }

  getDiaries(id: number, pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`farm/${id}/diaries`, false, pageSize, pageIndex);
  }

  createDiary(model): Observable<ResponseResult> {
    return this.commonService.createData(`farm/diary`, model, model.langId, model.parentId);
  }

  updateDiary(model): Observable<ResponseResult> {
    return this.commonService.updateData(`farm/diaries/${model.diaryId}`, model);
  }

  deleteDiary(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`farm/diaries/${id}`);
  }

  getHarvests(id: number, pageSize: number = Pagination.PageSize, pageIndex: number = Pagination.PageIndex): Observable<ResponseResult> {
    return this.commonService.getAll(`farm/${id}/harvests`, false, pageSize, pageIndex);
  }

  createHarvest(model): Observable<ResponseResult> {
    return this.commonService.createData(`farm/harvest`, model, model.langId, model.parentId);
  }

  updateHarvest(model): Observable<ResponseResult> {
    return this.commonService.updateData(`farm/harvests/${model.harvestId}`, model);
  }

  deleteHarvest(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`/farm/harvests/${id}`);
  }
}
