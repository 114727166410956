import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class ReportService {
  constructor(private commonService: CommonService) { }

  getTotalReport(): Observable<ResponseResult> {
    return this.commonService.getById(`report/total`);
  }

  getDataTerai(isWarning: boolean = false): Observable<ResponseResult> {
    return this.commonService.getById(`report/terai?isWarning=${isWarning}`);
  }

  getDataTeraiTv(): Observable<ResponseResult> {
    return this.commonService.getById(`report/terai-tv`);
  }

  getReportChecked(): Observable<ResponseResult> {
    return this.commonService.getById(`report/check`);
  }

  getReportByQrocde(qrcode: string): Observable<ResponseResult> {
    return this.commonService.getById(`report/trace?qrcode=${qrcode}`);
  }

  getSearch(search: string): Observable<ResponseResult> {
    return this.commonService.getById(`report/search?search=${search}`);
  }

  getDetailUser(id: number): Observable<ResponseResult> {
    return this.commonService.getById(`report/users/${id}`);
  }
}
