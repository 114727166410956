import { Component, OnInit, Input, SimpleChange, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigTableInterface } from 'src/app/shares/interfaces';
import { Action, Pagination, Types } from 'src/app/shares/constants';
import { AuthService } from 'src/app/shares/services';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent {

  @Input() configTable: ConfigTableInterface;
  @Output() actionClicked = new EventEmitter<any>();
  dataTable: any[];
  searchValue: string = '';
  pageSize: number = Pagination.PageSize;
  pageIndex: number = Pagination.PageIndex;
  totalPage: number = 0;
  clientSize: boolean = false;
  constructor(private authService: AuthService) {
    this.dataTable = [];
  }
  
  setData(data, total, clientSize: boolean = false) {
    this.configTable.Data = this.dataTable = data;
    this.totalPage = total;
    this.clientSize = false;
  }

  checkPerAction(action) {
    if(action == Action.Back)
      return true;
    return this.authService.checkPermissionAction(action);
  }

  dataProperties(data) {
    let results = this.setDefaultResult();
    Object.keys(data).forEach(e => {
      this.configTable.Header.forEach((value, index) => {
        if (value.Key === e) {
          let va = data[e];
          if (value.Type === Types.Date) {
            const t = new Date(va);
            va = t.getDate() + '/' + (t.getMonth() + 1) + '/' + t.getFullYear();
          }
          results[index] = va;
        }
      });
    });
    return results;
  }

  setDefaultResult() {
    let res = [];
    this.configTable.Header.forEach((value, index) => {
      res.push('');
    });
    return res;
  }

  searchChanged($event) {
    if (this.searchValue !== '') {
      this.filterData(this.searchValue);
    } else {
      this.dataTable = this.configTable.Data;
    }
  }

  filterData(filter: string) {
    this.dataTable = [];
    this.configTable.Data.forEach((element, index) => {
      let flag = false;
      Object.keys(element).forEach(e => {
        const val = element[e] + '';
        if (val.toLowerCase().includes(filter.toLowerCase())) { flag = true; }
      });
      if (flag) {
        this.dataTable.push(element);
      }
    });
  }

  eventactionClicked(key, data) {
    this.actionClicked.emit({
      action: key,
      data: data
    });
  }
}
