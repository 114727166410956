import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class PackageService {
  constructor(private commonService: CommonService) { }

  checkNumStart(type, isdetail): Observable<ResponseResult> {
    return this.commonService.getById(`package/check-numstart?type=${type}&detail=${isdetail}`);
  }

  getAllPackage(type,pageIndex: number = Pagination.PageIndex, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`package?type=${type}`, true, pageSize, pageIndex);
  }

  createPackage(model): Observable<ResponseResult> {
    return this.commonService.createData(`package`, model);
  }

  updatePackage(model): Observable<ResponseResult> {
    return this.commonService.updateData(`package/${model.id}`, model);
  }

  deletePackage(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`package/${id}`);
  }

  getAllPackageDetail(pageIndex: number = Pagination.PageIndex, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`package/package-details`, false, pageSize, pageIndex);
  }

  createPackageDetail(model): Observable<ResponseResult> {
    return this.commonService.createData(`package/package-detail`, model);
  }

  updatePackageDetail(model): Observable<ResponseResult> {
    return this.commonService.updateData(`package/package-details/${model.id}`, model);
  }

  deletePackageDetail(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`package/package-details/${id}`);
  }
}
