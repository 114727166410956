import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class CategoryService {
  constructor(private commonService: CommonService) { }

  CateGetAll(pageIndex: number = Pagination.PageIndex, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`product-category`, false, pageSize, pageIndex);
  }

  GetListproductForSalerBought(model): Observable<ResponseResult> {
    return this.commonService.createData(`product-category/saler-products/bought`, model);
  }

  ExportProductForSalerBought(model): Observable<ResponseResult> {
    return this.commonService.createData(`export/materials`, model);
  }

  CateGetById(id): Observable<ResponseResult> {
    return this.commonService.getById(`product-category/${id}/detail`);
  }

  CateCreate(model): Observable<ResponseResult> {
    return this.commonService.createData(`product-category`, model);
  }

  CateUpdate(model): Observable<ResponseResult> {
    return this.commonService.updateData(`product-category/${model.id}`, model);
  }

  CateDelete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`product-category/${id}`);
  }

  //
  RegisterGetAll(cateId: number, status: string, fromDate: any = null, toDate: any= null, pageIndex: number = Pagination.PageIndex, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    let url = `product-category/${cateId}/register?status=${status}`
    if(fromDate){
      url = url + `&fromDate=${fromDate}&toDate=${toDate}`
;    }
    return this.commonService.getAll(url, true, pageSize, pageIndex);
  }

  RegisterGetById(id): Observable<ResponseResult> {
    return this.commonService.getById(`product-category/register/${id}/detail`);
  }

  RegisterCreate(model): Observable<ResponseResult> {
    return this.commonService.createData(`product-category/register`, model);
  }

  RegisterUpdate(model): Observable<ResponseResult> {
    return this.commonService.updateData(`product-category/register/${model.id}`, model);
  }

  RegisterDelete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`product-category/register/${id}`);
  }

  //
  StatusCreate(model): Observable<ResponseResult> {
    return this.commonService.createData(`product-category/status`, model);
  }
  
}
