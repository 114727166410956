import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { NgModule } from '@angular/core';
import { NgZorroAntdModule, NzGridModule, NzTableModule, NzCardModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UploadFiledComponent } from './upload-filed/upload-filed.component';
import { DiaryComponent } from './diary/diary.component';
import { HarvestComponent } from './harvest/harvest.component';
import { RouterStateSnapshot } from '@angular/router';
import { RatingComponent } from './rating/rating.component';

@NgModule({
    imports: [
      CommonModule,
      NgZorroAntdModule,
      FormsModule,
      ReactiveFormsModule,
      TranslateModule,
      NzGridModule,
      NzTableModule,
      NzCardModule 
    ],
    declarations: [TableComponent, UploadFiledComponent, DiaryComponent, HarvestComponent, RatingComponent],
    exports: [
        TableComponent // <-- this!
        ,UploadFiledComponent,
        DiaryComponent,
        HarvestComponent,
        RatingComponent
    ]
  })
  export class ComponentsModule { }