import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class CompanyService {
  constructor(private commonService: CommonService) { }

  getAll(langId: string = '',
         pageIndex: number = Pagination.PageIndex,
         pageSize: number = Pagination.PageSize
         ): Observable<ResponseResult> {
    return this.commonService.getAll(`company`, false, pageSize, pageIndex, langId);
  }

  create(model): Observable<ResponseResult> {
    return this.commonService.createData(`company`, model, model.langId, model.parentId);
  }

  update(model): Observable<ResponseResult> {
    return this.commonService.updateData(`company/${model.companyId}`, model);
  }

}
