import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { StatusCode } from 'src/app/shares/constants';
import { GlobalsService } from 'src/app/shares/helpers';
import { UserService } from 'src/app/shares/services';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  rating: number = 0;
  @Input() userId: any;
  constructor(private userService: UserService,private globals: GlobalsService) { }

  ngOnInit() {
  }

  goRating(item){
    this.rating = item;
    this.createRating();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.getRating();
  }

  createRating() {
    if(!this.userId) return;
    const model = {
      userId: this.userId,
      rating:this.rating
    }
    this.userService.createRating(model).subscribe((res) => {
      if (res.statusCode === StatusCode.Status200OK) {
        
      } else {
        this.globals.getErrorMessage(res.message);
      }
    }, (error) => {
      this.globals.saveError();
    });
  }

  getRating() {
    if(!this.userId) return;
    this.userService.getRating(this.userId).subscribe((res) => {
      if (res.statusCode === StatusCode.Status200OK) {
        this.rating = res.data;
      } else {
        this.globals.getErrorMessage(res.message);
      }
    }, (error) => {
      this.globals.saveError();
    });
  }

}
