import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class LanguageService {
  constructor(private commonService: CommonService) { }

  getAll() {
    return this.commonService.getById(`languages`).subscribe((res) => {
      (res);
    });
  }
}
