import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class ReportFieldService {
  constructor(private commonService: CommonService) { }

  getReportFields(pageIndex: number = Pagination.PageIndex, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`report-field`, false, pageSize, pageIndex);
  }

  createReportField(model): Observable<ResponseResult> {
    return this.commonService.createData(`report-field`, model);
  }

  updateReportField(model): Observable<ResponseResult> {
    return this.commonService.updateData(`report-field/${model.id}`, model);
  }

  deleteReportField(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`report-field/${id}`);
  }

  getReportFieldUsers(userId: number,pageIndex: number = Pagination.PageIndex, pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`report-field/users/${userId}`, false, pageSize, pageIndex);
  }

  createReportFieldUser(model): Observable<ResponseResult> {
    return this.commonService.createData(`report-field/user-field`, model);
  }

  updateReportFieldUser(model): Observable<ResponseResult> {
    return this.commonService.updateData(`report-field/user-fields/${model.id}`, model);
  }

  copyReportFieldUser(model): Observable<ResponseResult> {
    return this.commonService.createData(`report-field/users/${model.userId}/copy`, model);
  }

  deleteReportFieldUser(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`report-field/user-fields/${id}`);
  }
}
