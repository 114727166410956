import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RoleService, UserService } from 'src/app/shares/services';
import { GlobalsService } from 'src/app/shares/helpers';
import { ResponseResult } from 'src/app/shares/interfaces';
import { StatusCode, Action } from 'src/app/shares/constants';

@Component({
  selector: 'app-user-role-detail',
  templateUrl: './user-role-detail.component.html',
  styleUrls: ['./user-role-detail.component.scss']
})
export class UserRoleDetailComponent implements OnInit {
  @Input() configDetail: any;
  @Output() eventAction = new EventEmitter<any>();
  dataRoles: any = [];
  model: any = {};
  constructor(private userService: UserService,
     private roleService: RoleService, 
     private globals: GlobalsService) {
    this.model = {};
    this.dataRoles = [];
  }

  ngOnInit() {
  }

  getRoles() {
    this.dataRoles = [];
    this.globals.showLoading();
    this.roleService.getAll().subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataRoles = res.data.items;
      } else {
        this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  editData(data) {
    this.model = data;
    this.getRoles();
  }

  open(): void {
    this.configDetail.visible = true;
  }

  close(): void {
    this.configDetail.visible = false;
  }

  selectRoles($event) {
    this.model.roles = $event;
  }

  checkRoles(id: number) {
    const check = this.model.roles.filter(value => {
      return value === id;
    });
    if (check.length > 0) {
      return true;
    }
    return false;
  }

  submitForm() {
    this.globals.showLoading();
    this.userService.updateUserRole(this.model.userId, this.model.roles).subscribe((res) => {
      this.globals.hideLoading();
      this.globals.saveSuccess();
      this.eventAction.emit({
        event: Action.Create,
        success: true
      });
      this.close();
    }, (error) => {
      this.globals.hideLoading();
      this.globals.saveError();
    });
  }

}
