import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefautLayoutComponent } from './layouts';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuardService } from './shares/services/auth-guard.service';
import { ReportTraceComponent } from './pages/report/report-trace/report-trace.component';
import { LayoutMapComponent } from './pages/map-info/layout-map/layout-map.component';
import { ReportProductCurdeComponent } from './pages/report/report-product-curde/report-product-curde.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'info',
    pathMatch: 'full',
  },
  {
    path: 'info',
    component: LayoutMapComponent,
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'trace',
    component: ReportTraceComponent
  },
  {
    path: 'product-crude',
    component: ReportProductCurdeComponent
  },
  {
    path: '',
    component: DefautLayoutComponent,
    data: {
      breadcrumb: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          breadcrumb: 'Dashboard'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'associations',
        loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule),
        data: {
          breadcrumb: 'Associations'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
        data: {
          breadcrumb: 'Users'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'suppliers',
        loadChildren: () => import('./pages/supplier/supplier.module').then(m => m.SupplierModule),
        data: {
          breadcrumb: 'Supplier'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'farmers',
        loadChildren: () => import('./pages/farmer/farmer.module').then(m => m.FarmerModule),
        data: {
          breadcrumb: 'Farmers'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'notifications',
        loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationModule),
        data: {
          breadcrumb: 'Notifications'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'news',
        loadChildren: () => import('./pages/new/new.module').then(m => m.NewModule),
        data: {
          breadcrumb: 'News'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'fertilizer-medicines',
        loadChildren: () => import('./pages/fertilizer-medicine/fertilizermedicine.module').then(m => m.FertilizerMedicineModule),
        data: {
          breadcrumb: 'Fertilizer medicine'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'role-rights',
        loadChildren: () => import('./pages/role-right/role-right.module').then(m => m.RoleRightModule),
        data: {
          breadcrumb: 'Role rights'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'farms',
        loadChildren: () => import('./pages/homenest/homenest.module').then(m => m.HomenestModule),
        data: {
          breadcrumb: 'Farm'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'verifieds',
        loadChildren: () => import('./pages/verified/verified.module').then(m => m.VerifiedModule),
        data: {
          breadcrumb: 'Verified home nest'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/report/report.module').then(m => m.ReportModule),
        data: {
          breadcrumb: 'Report'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'packages',
        loadChildren: () => import('./pages/package/package.module').then(m => m.PackageModule),
        data: {
          breadcrumb: 'Report'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'process',
        loadChildren: () => import('./pages/process/process.module').then(m => m.ProcessModule),
        data: {
          breadcrumb: 'Process'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'cates',
        loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule),
        data: {
          breadcrumb: 'Cates'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'products',
        loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule),
        data: {
          breadcrumb: 'Products'
        },
        canActivate: [AuthGuardService] 
      },
      {
        path: 'welcome',
        loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule),
        data: {
          breadcrumb: 'Welcome'
        },
        canActivate: [AuthGuardService] 
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
