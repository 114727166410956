export const LINK_QRCODE = 'http://vsfa.traceverified.com/trace?doc=';

export const ImageType = 'data:image/jpeg;base64,';

export const Local = {
    TOKEN: 'Token',
    User: 'User',
    FullName: 'FullName',
    UserName: 'UserName',
    Permission: 'Permission',
};

export const Action = {
    Create: 'Create',
    Back: 'Back',
    Edit: 'Edit',
    Delete: 'Delete',
    View: 'View',
    Translate: 'Translate',
    Qrcode: 'Qrcode',
    Filter: 'Filter',
    Copy: 'Copy',
    PrintQrcode: 'PrintQrcode',
    PageChange: 'PageChange'
};

export const RoleRight = [
    { Id: 1, Action: 'View', Name: 'Xem' },
    { Id: 2, Action: 'Create', Name: 'Tạo mới' },
    { Id: 3, Action: 'Edit', Name: 'Cập nhật' },
    { Id: 4, Action: 'Qrcode', Name: 'Qrcode' },
    { Id: 5, Action: 'Filter', Name: 'Filter' },
    { Id: 6, Action: 'Translate', Name: 'Translate' },
    { Id: 7, Action: 'Delete', Name: 'Delete' },
    { Id: 8, Action: 'Copy', Name: 'Copy' },
    { Id: 9, Action: 'PrintQrcode', Name: 'Xuất tem in qrcode' },
];

export const Lang = [
    { Id: '1', Code: 'vi', Name: 'Việt Nam', Default: true },
    { Id: '2', Code: 'en', Name: 'English', Default: false },
]

export const Pagination = {
    PageSize: 50,
    PageIndex: 1,
};

export const StatusCode = {
    Status200OK: '200',
    Status201Created: '201',
    Status202Accepted: '202',
    Status500InternalServerError: '500',
    Status401Unauthorized: '401',
};

export const MemberTypes = {
    Member: 2,
    Technical: 6,
    NoneMember: 7,
    Seller: 8,
    Association: 10,
};



export const Types = {
    Date: 'Date',
    Number: 'Number',
};

export const StatusVerifiedCode = {
    UnCheck: 0,
    Checked: 1,
    Fail: 2,
};


