import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigTableInterface, ResponseResult } from 'src/app/shares/interfaces';
import { TableComponent } from 'src/app/components';
import { UserGroupDetailComponent } from '../user-group-detail/user-group-detail.component';
import { Action, StatusCode, Pagination } from 'src/app/shares/constants';
import { NzModalService } from 'ng-zorro-antd';
import { TranslateHelpersService, GlobalsService } from 'src/app/shares/helpers';
import { UserGroupService } from 'src/app/shares/services/user-group.service';

@Component({
  selector: 'app-user-group-list',
  templateUrl: './user-group-list.component.html',
  styleUrls: ['./user-group-list.component.scss']
})
export class UserGroupListComponent implements OnInit {

  configTable: ConfigTableInterface = {
    Data: [],
    ColWidth: ['140px', '300px', '1200px'],
    ActionHeader: [
      //{ Key: Action.Create, Name: 'Globals.create', Icon: '' },
    ],
    Action: [
      //  { Key: Action.Edit, Name: 'Edit', Icon: 'edit' },
      //  { Key: Action.Delete, Name: 'Delete', Icon: 'delete' }
    ],
    Header: [
      { Key: 'name', Name: 'Usergroup.name' },
      { Key: 'description', Name: 'Usergroup.description' },
    ]
  };

  configDetail: any = {
    visible: false,
    model: null
  };

  @ViewChild('appiontable', { static: false }) appiontable: TableComponent
  @ViewChild('appdetail', { static: false }) appdetail: UserGroupDetailComponent

  constructor(private modalService: NzModalService,
    private userGroupService: UserGroupService,
    private translateHelpersService: TranslateHelpersService,
    private globals: GlobalsService) {
    this.configTable.Data = [];
  }

  ngOnInit() {
    this.getAll();
  }

  getAll(pageIndex: number = Pagination.PageIndex) {
    this.globals.showLoading();
    this.userGroupService.getAll(pageIndex).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.appiontable.setData(res.data.items, res.data.totalItems);
      } else {
        this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  actionClicked($event) {
    if ($event.action === Action.PageChange) {
      this.getAll($event.data);
    }
    if ($event.action === Action.Create) {
      this.configDetail.visible = true;
      this.appdetail.editData(null);
    }
    if ($event.action === Action.Edit) {
      this.configDetail.visible = true;
      this.appdetail.editData($event.data);
    }
    else if ($event.action === Action.Delete) {
      const ref = this.modalService.confirm({
        nzTitle: this.translateHelpersService.translate('Globals.confirm'),
        nzContent: this.translateHelpersService.translate('Globals.confirm-delete'),
        nzOkText: this.translateHelpersService.translate('Globals.ok'),
        nzOnOk: (res) => {
          this.globals.showLoading();
          this.userGroupService.delete($event.data.userGroupId).subscribe((res) => {
            this.globals.hideLoading();
            if (res.statusCode === StatusCode.Status200OK) {
              this.globals.saveSuccess();
              this.getAll();
            } else {
              this.globals.getErrorMessage(res.message);
            }
          }, (error) => {
            this.globals.hideLoading();
            this.globals.saveError();
          });
        },
        nzCancelText: this.translateHelpersService.translate('Globals.cancel'),
      });

    }
  }

  eventAction($event) {
    this.getAll();
  }
}
