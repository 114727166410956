import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageConstant, Lang } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class TranslateHelpersService {
  constructor(private trans: TranslateService) { }

  defaultLang(language: string = '') {
    if (language !== '') {
      localStorage.setItem(LanguageConstant.LOCALSTORAGE, language);
    } else {
      let lang = localStorage.getItem(LanguageConstant.LOCALSTORAGE);
      if (typeof lang == 'undefined' || lang == null) {
        const def = Lang.filter(v => {
          return v.Default === true;
        });
        lang = def[0].Id + '';
        localStorage.setItem(LanguageConstant.LOCALSTORAGE, lang);
      }
      language = lang;
    }
    const def = Lang.filter(v => {
      return v.Id + '' == language;
    });
    this.trans.setDefaultLang(def[0].Code);
    this.trans.use(def[0].Code);

  }

  getLocalLang() {
    return localStorage.getItem(LanguageConstant.LOCALSTORAGE);
  }

  translate(key: string, param: string = ''): string {
    let value: string;
    this.trans.get(key, { value: param }).subscribe((res: string) => {
      value = res;
    });
    return value;
  }
}
