import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalsService, TranslateHelpersService } from 'src/app/shares/helpers';
import { ReportService } from 'src/app/shares/services';
import { StatusCode, ImageType } from 'src/app/shares/constants';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ResponseResult } from 'src/app/shares/interfaces';

declare var google: any;
@Component({
  selector: 'app-report-trace',
  templateUrl: './report-trace.component.html',
  styleUrls: ['./report-trace.component.scss']
})
export class ReportTraceComponent implements OnInit {

  dataTerais: any;
  map: any;
  listMarker: any = [];
  dataResponse: any = {};
  qrCode: string = '';
  active: string = '';
  previewImage: string | undefined = '';
  previewVisible = false;
  tabIndex = 0;
  isclick = false;
  constructor(private activatedRoute: ActivatedRoute,
    private globalsService: GlobalsService,
    private reportService: ReportService,
    private globals: GlobalsService,
    private sanitizer: DomSanitizer,
    private translateHelpersService: TranslateHelpersService) {
    this.dataResponse = null;
    this.listMarker = [];
  }

  transform(v: string): SafeHtml {
    v = v.replace('watch?v=', 'embed/');
    return this.sanitizer.bypassSecurityTrustResourceUrl(v);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.qrCode = params['doc'];
      if (typeof this.qrCode != 'undefined') {
        this.getReport(this.qrCode);
      }
    });
  }

  initMap(Lat, Lng) {
    setTimeout(() => {
      const elm = document.getElementById("googleMapReport");
      console.log(elm);
      let mapProp = {
        center: new google.maps.LatLng(Lat, Lng),
        zoom: 8,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
      };
      this.map = new google.maps.Map(elm,
        mapProp
      );
    }, 100)

  }


  getReport(qrcode) {
    this.globals.showLoading();
    this.reportService.getReportByQrocde(qrcode).subscribe(res => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataResponse = res.data;
        this.initMap(15.645319, 108.248352);
        this.setMapLocation();
      } else {
        this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
    });
  }

  setMapLocation(){
    setTimeout(() => {
      this.dataResponse.locationFarm.forEach((value, index) => {
        if (value.lat && value.lng) {
          this.myMap(value.lat, value.lng, value.name, value);
        }
      });
      this.map.setZoom(10);
      this.map.setCenter(
        new google.maps.LatLng(
          this.dataResponse.locationFarm[0].lat,
          this.dataResponse.locationFarm[0].lng
        )
      );
    }, 100)
  }

  eventactionClicked(){
    this.clearOverlays();
    this.setMapLocation();
    if(!this.isclick){
      this.getDataFromTerai();
    }
    this.isclick = !this.isclick;
  }

  myMap(Lat, Lng, name, data, isDetail = false, isTerrai = false) {
    let markerPin = "assets/img/marker_green.png";
    if (data.pinColor == "#E74C3C") {
      markerPin = "assets/img/marker_red.png";
    } else if (data.pinColor == "#ec01fa") {
      markerPin = "assets/img/marker_pind.png";
    }
    if(!isTerrai){
       markerPin = "assets/img/oct.png";
    }
    let addressvt = `${data.xa},${data.huyen},${data.tinh}`;
    data.addressvt = addressvt;
    let marker = new google.maps.Marker({
      position: new google.maps.LatLng(Lat, Lng),
      map: this.map,
      data: data,
      icon: markerPin,
      label: "",
      title: name,
    });
    marker.addListener("click", function () {
      var contentString = "";
      if(!isTerrai){
          contentString = `<div id="content">
          <div class="cont-header">
            <div class="right">
              ${marker.data.name ? marker.data.name : ''}
            </div>
          </div>
          <div class="cont-body">
            <p><span>Chủ trang trại:</span> ${marker.data.ownerName ? marker.data.ownerName : ""}</p>
            <p><span>Địa chỉ:</span> ${marker.data.address ? marker.data.address : ""}</p>
            <p><span>Loại cây trồng:</span> ${marker.data.typeOfCrop ? marker.data.typeOfCrop : ""}</p>
            <p><span>Cây xen canh:</span> ${marker.data.intercroppingPlant ? marker.data.intercroppingPlant : ""}</p>
            <p><span>Năm trồng:</span> ${marker.data.yearPlanted ? marker.data.yearPlanted : ""}</p>
            <p><span>Diện tích:</span> ${marker.data.acreage ? marker.data.acreage : ""} m2</p>
            <p><span>Sản lượng:</span> ${marker.data.quantity ? marker.data.quantity : ""} kg/năm</p>
            <p><span>Chứng nhận:</span> ${marker.data.certifications ? marker.data.certifications : ""}</p>
            <p><span>Diện tích cây trồng chứng nhận:</span> ${marker.data.certificationArea ? marker.data.certificationArea : ""} m2</p>
            <p><span>Hiện trạng cây:</span> ${marker.data.treeStatus ? marker.data.treeStatus : ""}</p>
            <p><span>Mật độ:</span> ${marker.data.density ? marker.data.density : ""}</p>
          </div>
        </div>`;
      }else{
        contentString = `<div id="content">
        <div class="cont-header">
          <div class="right">
            ${marker.data.churung ? marker.data.churung : ''}
          </div>
        </div>
        <div class="cont-body">
          <p><span>Address:</span> ${marker.data.addressvt}</p>
          <p><span>Area:</span> ${marker.data.dtich ? marker.data.dtich : ""}</p>
          <p><span>Forest:</span> ${marker.data.loairung ? marker.data.loairung : ""}</p>
          <p><span>Prioritize:</span> ${marker.data.uutien ? marker.data.uutien : ""}</p>
        </div>
      </div>`;
      }
     

      var infowindow = new google.maps.InfoWindow({
        content: contentString,
      });
      infowindow.open(this.map, marker);
    });
    marker.setMap(this.map);
    this.listMarker.push(marker);
  }

  checkImage(image: string) {
    if (image != null && image != 'null' && image != "")
      return (ImageType + image);
    else return 'assets/img/no-image.svg';
  }

  setDefaultLang(lang: string) {
    this.translateHelpersService.defaultLang(lang);
  }

  getImage(image) {
    if (image) {
      return this.globals.decodeImage(image);
    }
    return '../assets/img/farm.png'
  }

  getImageByLink(type, id, index = 0) {
    return this.globals.getAvartaFlow(type, id, index);
  }

  goActive(type, id) {
    if (this.active != type + id)
      this.active = type + id;
    else
      this.active = '';
  }

  showImage(image) {
    this.previewImage = this.getImage(image);
    this.previewVisible = true;
  }

  showImageByLink(type, id, index = 0) {
    this.previewImage = this.getImageByLink(type, id, index);
    this.previewVisible = true;
  }

  tabChanged($event) {
    if ($event == 2) {
      document.getElementById("mapreport").classList.remove("done-show");
    } else {
      document.getElementById("mapreport").classList.add("done-show");
    }
  }

  clearOverlays() {
    for (var i = 0; i < this.listMarker.length; i++ ) {
      this.listMarker[i].setMap(null);
    }
    this.listMarker.length = 0;
  }

  getDataFromTerai() {
    this.dataTerais = [];
    this.globals.showLoading();
    this.reportService.getDataTerai(true).subscribe(
      (res: ResponseResult) => {
        this.globals.hideLoading();
        if (res.statusCode === StatusCode.Status200OK) {
          this.dataTerais = res.data;
          this.goMarkerNear();
        } else {
          this.dataTerais = [];
        }
      },
      (error) => {
        this.globals.hideLoading();
        this.globalsService.getError();
      }
    );
  }

  goMarkerNear() {
    let markerNear = [];
    let curLat = '';
    let curLng = '';
    this.dataResponse.locationFarm.forEach(element => {
      curLat = element.lat;
      curLng = element.lng;
      this.dataTerais.forEach((value, index) => {
        if (value.vido && value.kinhdo) {
          var distMeter = this.distance(value.vido, value.kinhdo, curLat, curLng);
          if (distMeter < 2 && !markerNear.includes(`${value.vido};${value.kinhdo}`)) {
            markerNear.push(`${value.vido};${value.kinhdo}`)
            this.myMap(value.vido, value.kinhdo, value.churung, value, false, true);
          }
        }
      });
    });
  }

  distance(lat1, lng1, lat2, lng2) {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var radlon1 = Math.PI * lng1 / 180;
    var radlon2 = Math.PI * lng2 / 180;
    var theta = lng1 - lng2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;

    //Get in in kilometers
    dist = dist * 1.609344;
    return dist;
  }

}
