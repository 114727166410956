import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateHelpersService, GlobalsService } from 'src/app/shares/helpers';
import { HomeNestService } from 'src/app/shares/services';
import { ResponseResult } from 'src/app/shares/interfaces';
import { StatusCode } from 'src/app/shares/constants';

@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss']
})
export class DiaryComponent implements OnInit {

  @Input() homeNestId: number;
  isCreate: boolean = true;
  fromdue: FormGroup;
  isDueForm: boolean = false;
  listOfData: any = [];
  dataModel: any = {};

  constructor(private fb: FormBuilder,
    private homeNestService: HomeNestService,
    private translate: TranslateHelpersService,
    private globals: GlobalsService) {
    this.dataModel = {};
    this.buildForm();
  }

  ngOnChanges() {
    if (this.homeNestId > 0) {
      this.getAll();
    }
  }

  getAll() {
    this.globals.showLoading();
    this.homeNestService.getDiaries(this.homeNestId).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.listOfData = res.data.items;
      } else {
        this.listOfData = [];
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.fromdue = this.fb.group({
      time: [this.dataModel.time, [
        Validators.required
      ]],
      conNh3H2s: [this.dataModel.conNh3H2s, []],
      colorNest: [this.dataModel.colorNest, []],
      totalNest: [this.dataModel.totalNest, []],
      qualityA: [this.dataModel.qualityA, []],
      qualityB: [this.dataModel.qualityB, []],
      qualityC: [this.dataModel.qualityC, []],
      humidity: [this.dataModel.humidity, []],
      humidityTime: [this.dataModel.humidityTime, []],
      humidityStatus: [this.dataModel.humidityStatus, []],
      material: [this.dataModel.material, []],
    });
    this.isDueForm = true;
  }

  startEdit(data) {
    this.isDueForm = false;
    this.isCreate = false;
    setTimeout(() => {
      this.dataModel = data;
      this.buildForm();
      this.isDueForm = true;
    });
  }

  reset() {
    this.isDueForm = false;
    this.isCreate = true;
    setTimeout(() => {
      this.dataModel = {};
      this.buildForm();
      this.isDueForm = true;
    });
  }

  submitDiaryForm() {
    if (!this.fromdue.valid) { return; }
    this.convertObject(this.fromdue.value);

    if (this.isCreate) {
      this.globals.showLoading();
      this.dataModel.homeNestId = this.homeNestId;
      this.homeNestService.createDiary(this.dataModel).subscribe((res) => {
        this.globals.hideLoading();
        if (res.statusCode === StatusCode.Status200OK) {
          this.globals.saveSuccess();
          this.reset();
          this.getAll();
        } else {
          this.globals.getErrorMessage(res.message);
        }
      }, (error) => {
        this.globals.hideLoading();
        this.globals.saveError();
      });
    } else {
      this.globals.showLoading();
      this.homeNestService.updateDiary(this.dataModel).subscribe((res) => {
        this.globals.hideLoading();
        if (res.statusCode === StatusCode.Status200OK) {
          this.globals.saveSuccess();
          this.reset();
          this.getAll();
        } else {
          this.globals.getErrorMessage(res.message);
        }
      }, (error) => {
        this.globals.hideLoading();
        this.globals.saveError();
      });
    }

  }

  convertObject(data) {
    Object.keys(data).forEach(e => {
      this.dataModel[e] = data[e];
    });
  }

  confirmDelete(data) {
    this.globals.showLoading();
    this.homeNestService.deleteDiary(data.diaryId).subscribe((res) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.globals.saveSuccess();
        this.reset();
        this.getAll();
      } else {
        this.globals.getErrorMessage(res.message);
      }
    });
  }

  cancel() {

  }

}
