import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class PackageNoService {
  constructor(private commonService: CommonService) { }

  packageNoGetAll(pageIndex: number = Pagination.PageIndex,pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`package-no`, false, pageSize, pageIndex);
  }

  packageInfoGetAll(pageIndex: number = Pagination.PageIndex,pageSize: number = Pagination.PageSize): Observable<ResponseResult> {
    return this.commonService.getAll(`package-no/infos`, false, pageSize, pageIndex);
  }

  packageNoExcel(id): Observable<ResponseResult> {
    return this.commonService.getById(`package-no/excel/${id}`);
  }

  getPackageStartMax(): Observable<ResponseResult> {
    return this.commonService.getById(`package-no/package-start`);
  }

  packageNoCreate(model): Observable<ResponseResult> {
    return this.commonService.createData(`package-no`, model);
  }

  packageNoUpdate(model): Observable<ResponseResult> {
    return this.commonService.updateData(`package-no/${model.id}`, model);
  }

  packageInfoCreate(model): Observable<ResponseResult> {
    return this.commonService.createData(`package-no/info`, model);
  }

  packageProductCreate(model): Observable<ResponseResult> {
    return this.commonService.createData(`package-no/info/materials`, model);
  }

  packageProductGetByPID(id): Observable<ResponseResult> {
    return this.commonService.getById(`package-no/info/${id}/materials`);
  }

  packageInfoUpdate(model): Observable<ResponseResult> {
    return this.commonService.updateData(`package-no/info/${model.id}`, model);
  }

  packageInfoGetById(id): Observable<ResponseResult> {
    return this.commonService.getById(`package-no/info/${id}`);
  }

  packageInfoDelete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`package-no/info/${id}`);
  }

  packageNoDelete(id): Observable<ResponseResult> {
    return this.commonService.deleteData(`package-no/${id}`);
  }

  packageNoGetById(id): Observable<ResponseResult> {
    return this.commonService.getById(`package-no/${id}/detail`);
  }
}
