import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { TranslateHelpersService } from './translate-helpers.service';
import { LINK_QRCODE, Local } from '../constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  constructor(private message: NzMessageService, private translateHelpersService: TranslateHelpersService) {
  }

  saveSuccess() {
    this.message.create('success', this.translateHelpersService.translate('Globals.save-success'));
  }

  saveError() {
    this.message.create('error', this.translateHelpersService.translate('Globals.save-error'));
  }

  deleteSuccess() {
    this.message.create('success', this.translateHelpersService.translate('Globals.delete-success'));
  }

  deleteError() {
    this.message.create('error', this.translateHelpersService.translate('Globals.delete-error'));
  }

  getError() {
    this.message.create('error', this.translateHelpersService.translate('Globals.get-error'));
  }

  getErrorMessage(message: string) {
    this.message.create('error', message);
  }

  showLoading() {
    const id = document.getElementById('hl-loading');
    id.style.display = 'flex';
  }

  hideLoading() {
    const id = document.getElementById('hl-loading');
    id.style.display = 'none';
  }

  goGenarateQrcode(type: string, userID: string) {
    const pad = '000000';
    const user = (pad + userID).slice(-pad.length);
    return type + user;
  }

  getUserInfo() {
    const user = localStorage.getItem(Local.User);
    if (user != null) {
      return JSON.parse(user);
    }
    return null;
  }

  encodeImage(image: string) {
    if(image && image != ''){
      const imageRes = image.split('base64,');
      if (imageRes.length > 1) {
        return imageRes[1];
      }
    }
    return '';
  }

  decodeImage(image: string) {
    const imageRes = 'data:image/png;base64,' + image;
    return imageRes;
  }

  getAvartaUser(userId){
    return `${environment.APIEndpoint}users/${userId}/avatar`
  }

  uploadImageEditor(){
    return `${environment.APIEndpoint}report/upd-editor`
  }

  getAvartaFlow(type,id,index=0){
    return `${environment.APIEndpoint}process/flow-steps/${id}/image?type=${type}&index=${index}`
  }
}
