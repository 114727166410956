import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class RightService {
  constructor(private commonService: CommonService) { }

  getAll(): Observable<ResponseResult> {
    return this.commonService.getById(`right`);
  }

  update(rightGroupId, model): Observable<ResponseResult> {
    return this.commonService.updateData(`right/${rightGroupId}`, model);
  }

  getRightGroups(moduleId : number): Observable<ResponseResult> {
    return this.commonService.getById(`role-right/modules/${moduleId}/right-group`);
  }

  getRoleRight(): Observable<ResponseResult> {
    return this.commonService.getById(`role-right`);
  }

  updateRoleRight(moduleId, roleId, model): Observable<ResponseResult> {
    return this.commonService.updateData(`role-right/modules/${moduleId}/roles/${roleId}`, model);
  }
}
