import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateHelpersService, GlobalsService } from 'src/app/shares/helpers';
import { HomeNestService } from 'src/app/shares/services';
import { ResponseResult } from 'src/app/shares/interfaces';
import { StatusCode, ImageType } from 'src/app/shares/constants';

@Component({
  selector: 'app-harvest',
  templateUrl: './harvest.component.html',
  styleUrls: ['./harvest.component.scss']
})
export class HarvestComponent implements OnInit {

  @Input() homeNestId: number;
  isCreate: boolean = true;
  fromdue: FormGroup;
  isDueForm: boolean = false;
  listOfData: any = [];
  dataModel: any = {};

  constructor(private fb: FormBuilder,
    private homeNestService: HomeNestService,
    private translate: TranslateHelpersService,
    private globals: GlobalsService) {
    this.dataModel = {};
    this.buildForm();
  }

  ngOnChanges() {
    if (this.homeNestId > 0) {
      this.getAll();
    }
  }

  getAll() {
    this.globals.showLoading();
    this.homeNestService.getHarvests(this.homeNestId).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.listOfData = res.data.items;
      } else {
        this.listOfData = [];
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.fromdue = this.fb.group({
      time: [this.dataModel.time, [
        Validators.required
      ]],
      totalNest: [this.dataModel.totalNest, []],
    });
    this.isDueForm = true;
  }

  startEdit(data) {
    this.isDueForm = false;
    this.isCreate = false;
    this.dataModel = {};
    setTimeout(() => {
      this.dataModel = {...data};
      this.dataModel.imageFirst = ImageType +  this.dataModel.imageFirst;
      this.dataModel.imageSecond = ImageType +  this.dataModel.imageSecond;
      this.dataModel.imageThird = ImageType +  this.dataModel.imageThird;
      this.buildForm();
      this.isDueForm = true;
    });
  }

  reset() {
    this.isDueForm = false;
    this.isCreate = true;
    setTimeout(() => {
      this.dataModel = {};
      this.buildForm();
      this.isDueForm = true;
    });
  }

  submitDiaryForm() {
    if (!this.fromdue.valid) { return; }
    this.convertObject(this.fromdue.value);

    if(this.dataModel.imageFirst){
      const Image = this.dataModel.imageFirst.split('base64,');
      this.dataModel.imageFirst = Image[1];
    }

    if(this.dataModel.imageSecond){
      const Image = this.dataModel.imageSecond.split('base64,');
      this.dataModel.imageSecond = Image[1];
    }

    if(this.dataModel.imageThird){
      const Image = this.dataModel.imageThird.split('base64,');
      this.dataModel.imageThird = Image[1];
    }

    if (this.isCreate) {
      this.globals.showLoading();
      this.dataModel.homeNestId = this.homeNestId;
      this.homeNestService.createHarvest(this.dataModel).subscribe((res) => {
        this.globals.hideLoading();
        if (res.statusCode === StatusCode.Status200OK) {
          this.globals.saveSuccess();
          this.reset();
          this.getAll();
        } else {
          this.globals.getErrorMessage(res.message);
        }
      }, (error) => {
        this.globals.hideLoading();
        this.globals.saveError();
      });
    } else {
      this.globals.showLoading();
      this.homeNestService.updateHarvest(this.dataModel).subscribe((res) => {
        this.globals.hideLoading();
        if (res.statusCode === StatusCode.Status200OK) {
          this.globals.saveSuccess();
          this.reset();
          this.getAll();
        } else {
          this.globals.getErrorMessage(res.message);
        }
      }, (error) => {
        this.globals.hideLoading();
        this.globals.saveError();
      });
    }

  }

  convertObject(data) {
    Object.keys(data).forEach(e => {
      this.dataModel[e] = data[e];
    });
  }

  confirmDelete(data) {
    this.globals.showLoading();
    this.homeNestService.deleteHarvest(data.harvestId).subscribe((res) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.globals.saveSuccess();
        this.reset();
        this.getAll();
      } else {
        this.globals.getErrorMessage(res.message);
      }
    });
  }

  cancel() {
  }

  changeListener(type, $event): void {
    if(!this.validateSize($event.target)){
      this.globals.getErrorMessage("Kích thước hình ảnh phải nhỏ hơn 1MB");
      return;
    }
    this.readThis(type, $event.target);
  }

  readThis(type, inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.dataModel[type] = myReader.result + '';
    };
    myReader.readAsDataURL(file);
  }

  validateSize(inputValue: any) {
    let fileSize = inputValue.files[0].size / 1024 / 1024; // in MB
    if (fileSize > 1) {
     return false;
    }
    return true;
  }

}
