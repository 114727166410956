import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Pagination, StatusCode } from '../constants';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class ExportService {
  constructor(private commonService: CommonService) { }

  getTotalReport(id): Observable<ResponseResult> {
    return this.commonService.getById(`export/print-qrcode/${id}`);
  }

  getTotalReportDetail(id): Observable<ResponseResult> {
    return this.commonService.getById(`export/print-qrcode-detail/${id}`);
  }
}
