import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigTableInterface, ResponseResult } from 'src/app/shares/interfaces';
import { Action, StatusCode } from 'src/app/shares/constants';
import { TableComponent } from 'src/app/components';
import { NzModalService } from 'ng-zorro-antd';
import { UserGroupService } from 'src/app/shares/services/user-group.service';
import { TranslateHelpersService, GlobalsService } from 'src/app/shares/helpers';
import { UserService } from 'src/app/shares/services/user.service';
import { UserRoleDetailComponent } from '../user-role-detail/user-role-detail.component';

@Component({
  selector: 'app-user-role-list',
  templateUrl: './user-role-list.component.html',
  styleUrls: ['./user-role-list.component.scss']
})
export class UserRoleListComponent implements OnInit {
  dataFilter: any;
  configTable: ConfigTableInterface = {
    Data: [],
    ColWidth: ['100px', '200px', '200px', '200px', '800px'],
    ActionHeader: [
      //{ Key: Action.Create, Name: 'Globals.create', Icon: '' },
    ],
    Action: [
      { Key: Action.Edit, Name: 'Edit', Icon: 'edit' },
    ],
    Header: [
      { Key: 'userCode', Name: 'User.usercode' },
      { Key: 'userName', Name: 'User.username' },
      { Key: 'fullName', Name: 'User.fullname' },
      { Key: 'roleNames', Name: 'roleright.roleNames' },
    ]
  };

  configDetail: any = {
    visible: false,
    model: null
  };

  @ViewChild('appiontable', { static: false }) appiontable: TableComponent;
  @ViewChild('appdetail', { static: false }) appdetail: UserRoleDetailComponent;

  constructor(private modalService: NzModalService,
    private userGroupService: UserGroupService,
    private userService: UserService,
    private translateHelpersService: TranslateHelpersService,
    private globals: GlobalsService) {
    this.configTable.Data = [];
    this.dataFilter = {};
  }

  ngOnInit() {
    this.getUserGroups();
  }

  getUserGroups() {
    this.globals.showLoading();
    this.userGroupService.getAll().subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.dataFilter.data = res.data.items;
        this.dataFilter.value = res.data.items[0].userGroupId;
        this.getUser(this.dataFilter.value);
      } else {
        this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  getUser(id: number) {
    this.globals.showLoading();
    this.userService.getUserRoles(id).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.appiontable.setData(res.data, res.data.length, true);
      } else {
        this.appiontable.setData([], 0, true);
        this.globals.getErrorMessage(res.message);
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  actionClicked($event) {
    if ($event.action === Action.Create) {
      this.configDetail.visible = true;
      this.appdetail.editData($event.data);
    }
    if ($event.action === Action.Edit) {
      this.configDetail.visible = true;
      this.appdetail.editData($event.data);
    }
  }

  filterData($event) {
    if ($event != null) {
      this.getUser($event);
    }
  }

  eventAction($event) {
    this.getUser(this.dataFilter.value);
  }

}

