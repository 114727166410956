import { Component, OnInit } from "@angular/core";
import { Local, ImageType, StatusCode } from "src/app/shares/constants";
import { GlobalsService } from "src/app/shares/helpers";
import { ResponseResult } from "src/app/shares/interfaces";
import { Router } from "@angular/router";
import {
  AuthService,
  HomeNestService,
  ReportService,
} from "src/app/shares/services";
declare var google: any;
declare var MarkerClusterer: any;
@Component({
  selector: "app-layout-map",
  templateUrl: "./layout-map.component.html",
  styleUrls: ["./layout-map.component.scss"],
})
export class LayoutMapComponent implements OnInit {
  map: any;
  checkedGop:boolean = true;
  searchTerai: string = "";
  markerCluster: any;
  typeData: any;
  typeLevel: any;
  dataUuTien: any;
  dataTeraiGrids: any;
  isShowFooter: boolean = true;
  visisabledDetailFarmer: boolean = false;
  visisabledListSupplier: boolean = false;
  visisabledDetailSupplier: boolean = false;
  detailSupplier: any = {};
  listMarker: any = [];
  fullName: string = localStorage.getItem(Local.FullName);
  dataDetail: any;
  dataTvs: any;
  dataSearch: any;
  dataTerais: any;
  search: string;
  dataListFarmInfo: any;
  isAuthenticated: boolean = false;
  typeMap: string = "roadmap";
  listFarms: any = [];
  constructor(
    private globalsService: GlobalsService,
    private reportService: ReportService,
    private homeNestService: HomeNestService,
    private router: Router,
    private authService: AuthService
  ) {
    this.dataDetail = {};
    this.dataListFarmInfo = {};
    this.listMarker = [];
    this.dataSearch = [];
    this.dataTerais = [];
    this.dataTvs = [];
    this.listFarms = [];
    this.dataTeraiGrids = [];
    this.dataUuTien = {};
    this.visisabledListSupplier = false;
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  ngOnInit() {
    this.initMap(15.645319, 108.248352);
    this.getListFarm();
    this.getDataFromTerai();
    this.getDataFromTeraiTv();
  }

  initMap(Lat, Lng) {
    let mapProp = {
      center: new google.maps.LatLng(Lat, Lng),
      zoom: 5,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
    };
    this.map = new google.maps.Map(
      document.getElementById("googleMap"),
      mapProp
    );
  }

  myMap(Lat, Lng, name, data, isDetail = false, isTerrai = false) {
    let markerPin = "assets/img/marker_green.png";
    if (data.pinColor == "#E74C3C") {
      markerPin = "assets/img/marker_red.png";
    } else if (data.pinColor == "#ec01fa") {
      markerPin = "assets/img/marker_pind.png";
    }
    let addressvt = `${data.xa},${data.huyen},${data.tinh}`;
    if(data.isTvf == true){
      addressvt = data.diadanh;
      markerPin = "assets/img/oct.png";
    }
    if(isDetail){
      markerPin = "assets/img/oct.png";
    }
    data.addressvt = addressvt;
    let marker = new google.maps.Marker({
      position: new google.maps.LatLng(Lat, Lng),
      map: this.map,
      data: data,
      icon: markerPin,
      label: "",
      title: name,
    });
    const par = this;

    marker.addListener("click", function () {
      var contentString = "";
      if (par.typeData === "Terai" || isTerrai) {
        var contentString = `<div id="content">
          <div class="cont-header">
            <div class="right">
              ${marker.data.churung ? marker.data.churung : ''}
            </div>
          </div>
          <div class="cont-body">
            <p><span>Address:</span> ${marker.data.addressvt}</p>
            <p><span>Area:</span> ${marker.data.dtich ? marker.data.dtich : ""}</p>
            <p><span>Forest:</span> ${marker.data.loairung ? marker.data.loairung : ""}</p>
            <p><span>Prioritize:</span> ${marker.data.uutien ? marker.data.uutien : ""}</p>
          </div>
        </div>`;
      } else {
        var contentString = `<div id="content">
          <div class="cont-header">
            <div class="right">
              ${marker.data.name ? marker.data.name : ''}
            </div>
          </div>
          <div class="cont-body">
            <p><span>Chủ trang trại:</span> ${marker.data.ownerName ? marker.data.ownerName : ""}</p>
            <p><span>Địa chỉ:</span> ${marker.data.address ? marker.data.address : ""}</p>
            <p><span>Loại cây trồng:</span> ${marker.data.typeOfCrop ? marker.data.typeOfCrop : ""}</p>
            <p><span>Cây xen canh:</span> ${marker.data.intercroppingPlant ? marker.data.intercroppingPlant : ""}</p>
            <p><span>Năm trồng:</span> ${marker.data.yearPlanted ? marker.data.yearPlanted : ""}</p>
            <p><span>Diện tích:</span> ${marker.data.acreage ? marker.data.acreage : ""} m2</p>
            <p><span>Sản lượng:</span> ${marker.data.quantity ? marker.data.quantity : ""} kg/năm</p>
            <p><span>Chứng nhận:</span> ${marker.data.certifications ? marker.data.certifications : ""}</p>
            <p><span>Diện tích cây trồng chứng nhận:</span> ${marker.data.certificationArea ? marker.data.certificationArea : ""} m2</p>
            <p><span>Hiện trạng cây:</span> ${marker.data.treeStatus ? marker.data.treeStatus : ""}</p>
            <p><span>Mật độ:</span> ${marker.data.density ? marker.data.density : ""}</p>
          </div>
        </div>`;
      }

      var infowindow = new google.maps.InfoWindow({
        content: contentString,
      });
      infowindow.open(this.map, marker);
    });
    this.listMarker.push(marker);
  }

  getListFarm() {
    this.globalsService.showLoading();
    this.deleteMarkers();

    this.homeNestService.getFarmMap(0).subscribe(
      (res: ResponseResult) => {
        this.globalsService.hideLoading();
        if (res.statusCode === StatusCode.Status200OK) {
          this.listFarms = res.data.items;
          this.listFarms.forEach((value, index) => {
            if (value.lat && value.lng) {
              this.myMap(value.lat, value.lng, value.address, value);
            }
          });
          setTimeout(() => {
            this.markerCluster = new MarkerClusterer(
              this.map,
              this.listMarker,
              {
                imagePath:
                  "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
              }
            );
          });
        } else {
          this.listFarms = [];
        }
      },
      (error) => {
        this.globalsService.getError();
        this.globalsService.hideLoading();
      }
    );
  }

  deleteMarkers() {
    if (this.markerCluster) this.markerCluster.removeMarkers(this.listMarker);
    this.listMarker.forEach((element) => {
      element.setMap(null);
    });
    this.listMarker = [];
    if (this.markerCluster) this.markerCluster.setMap(null);
  }

  getAvatarUser() {
    const user = this.globalsService.getUserInfo();
    if (user != null) {
      if (user.avatar != null) {
        return ImageType + user.avatar;
      }
    }
    return "assets/img/boy.png";
  }

  actionClicked(type, $event) {
    if (type === "DetailFarmer") {
      if ($event.action === "Close") {
        const configList = { ...this.dataListFarmInfo };
        this.dataListFarmInfo = { ...configList };
        this.dataListFarmInfo.userActive = null;
        this.visisabledDetailFarmer = false;
        this.deleteMarkers();
      }
      if ($event.action === "TTTT") {
        this.goMarkerNear();
      }
    }
    if (type === "ListSupplier") {
      if ($event.action === "Close") {
        this.visisabledListSupplier = false;
        const configList = { ...this.dataListFarmInfo };
        this.dataListFarmInfo = { ...configList };
        this.dataListFarmInfo.userActive = null;
        this.dataListFarmInfo.leaderId = 0;
        this.visisabledDetailFarmer = false;
        this.visisabledDetailSupplier = false;
      }
      if ($event.action === "Detail") {
        this.visisabledDetailSupplier = true;
        this.detailSupplier = $event.data;

        const configList = { ...this.dataListFarmInfo };
        this.dataListFarmInfo = { ...configList };
        this.dataListFarmInfo.userActive = null;
        this.dataListFarmInfo.leaderId = this.detailSupplier.userId;
      }
    }
    if (type === "ListFarmer") {
      if ($event.action === "Detail") {
        this.visisabledDetailFarmer = true;
        this.dataDetail = $event.data;
        const configList = { ...this.dataListFarmInfo };
        this.dataListFarmInfo = { ...configList };
        this.dataListFarmInfo.userActive = this.dataDetail.userId;
        this.deleteMarkers();
        if (this.dataDetail.listFarm.length > 0) {
          setTimeout(() => {
            this.dataDetail.listFarm.forEach((value, index) => {
              if (value.lat && value.lng) {
                this.myMap(value.lat, value.lng, value.address, value, true);
              }
            });
            setTimeout(() => {
              this.markerCluster = new MarkerClusterer(
                this.map,
                this.listMarker,
                {
                  imagePath:
                    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                }
              );
            });
            this.map.setZoom(10);
            this.map.setCenter(
              new google.maps.LatLng(
                this.dataDetail.listFarm[0].lat,
                this.dataDetail.listFarm[0].lng
              )
            );
          });
        }
      }
    }
  }

  goDetailItem(item) {
    if (item.isFarm) {
      this.getDetailUser(item.id, true);
    }
  }

  getDetailUser(userId, isDetail=false) {
    this.globalsService.showLoading();
    this.deleteMarkers();

    this.reportService.getDetailUser(userId).subscribe(
      (res: ResponseResult) => {
        this.globalsService.hideLoading();
        if (res.statusCode === StatusCode.Status200OK) {
          this.dataDetail = res.data;
          this.visisabledDetailFarmer = true;
          if (this.dataDetail.listFarm.length > 0) {
            setTimeout(() => {
              this.dataDetail.listFarm.forEach((value, index) => {
                value.name = this.dataDetail.fullName;
                if (value.lat && value.lng) {
                  this.myMap(value.lat, value.lng, value.address, value, isDetail);
                }
              });
              setTimeout(() => {
                this.markerCluster = new MarkerClusterer(
                  this.map,
                  this.listMarker,
                  {
                    imagePath:
                      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                  }
                );
              });
              this.map.setZoom(10);
              this.map.setCenter(
                new google.maps.LatLng(
                  this.dataDetail.listFarm[0].lat,
                  this.dataDetail.listFarm[0].lng
                )
              );
            });
          }
        } else {
          this.dataDetail = {};
          this.visisabledDetailFarmer = false;
        }
      },
      (error) => {
        this.globalsService.getError();
        this.globalsService.hideLoading();
      }
    );
  }

  goShowOnMap(data) {
    this.deleteMarkers();
    setTimeout(() => {
      this.myMap(data.vido, data.kinhdo, data.churung, data);
      this.map.setZoom(10);
      this.map.setCenter(new google.maps.LatLng(data.vido, data.kinhdo));
      setTimeout(()=>{
      this.openMapTerai(this.dataTvs, false);
      })
    });
  }

  openListSupplier() {
    this.visisabledListSupplier = !this.visisabledListSupplier;
    this.visisabledDetailSupplier = false;
    this.visisabledDetailFarmer = false;

    const configList = { ...this.dataListFarmInfo };
    this.dataListFarmInfo = { ...configList };
    this.dataListFarmInfo.userActive = null;
    this.dataListFarmInfo.leaderId = 0;
  }

  goDetailSupplier() {
    this.visisabledDetailFarmer = true;
    this.dataDetail = this.detailSupplier;
  }

  goDashboard() {
    this.router.navigateByUrl("dashboard");
  }

  goLogin() {
    this.router.navigateByUrl("login");
  }

  getImage(item) {
    return this.globalsService.getAvartaUser(item.id);
  }

  onChangeTypeMap($event) {
    setTimeout(() => {
      if ($event === "all") {
        this.visisabledDetailSupplier = false;
        this.visisabledDetailFarmer = false;
        const configList = { ...this.dataListFarmInfo };
        this.dataListFarmInfo = { ...configList };
        this.dataListFarmInfo.userActive = null;

        this.getListFarm();
      } else {
        this.map.setMapTypeId($event);
      }
    }, 200);
  }

  getDataFromTerai() {
    this.dataTerais = [];
    this.dataUuTien = {};
    this.reportService.getDataTerai().subscribe(
      (res: ResponseResult) => {
        if (res.statusCode === StatusCode.Status200OK) {
          this.dataTeraiGrids = res.data;
          this.dataTerais = res.data;
          this.dataUuTien.hight = this.dataTerais.filter((x) => x.pinColor === "#E74C3C");;
          this.dataUuTien.middle = this.dataTerais.filter((x) => x.pinColor === "#ec01fa");
          this.dataUuTien.low =this.dataTerais.filter((x) => x.pinColor === "#03A9F4");
          this.getDataFromTeraiTv();
        } else {
          this.dataTerais = [];
        }
      },
      (error) => {
        this.globalsService.getError();
      }
    );
  }

  getDataFromTeraiTv() {
    this.dataTvs = [];
    this.reportService.getDataTeraiTv().subscribe(
      (res: ResponseResult) => {
        if (res.statusCode === StatusCode.Status200OK) {
          this.dataTvs = res.data;
        } else {
          this.dataTvs = [];
        }
      },
      (error) => {
        this.globalsService.getError();
      }
    );
  }

  onChangeLevel($event) {
    if ($event === "High") {
      this.dataTeraiGrids = this.dataUuTien.hight;
      this.openMapTerai(this.dataUuTien.hight);
    } else if ($event === "Middle") {
      this.dataTeraiGrids = this.dataUuTien.middle;
      this.openMapTerai(this.dataUuTien.middle);
    } else if ($event === "All") {
      this.dataTeraiGrids = this.dataTerais;
      this.openMapTerai(this.dataTerais);
    } else {
      this.dataTeraiGrids = this.dataUuTien.low;
      this.openMapTerai(this.dataUuTien.low);
    }
  }

  modelChangedTerai($event) {
    this.dataTeraiGrids = [];
    this.dataTerais.forEach((element, index) => {
      if (element.churung.toLowerCase().includes($event.toLowerCase())) {
        this.dataTeraiGrids.push(element);
      }
    });
  }

  onChangeTypeData($event) {
    if ($event === "Terai") {
      this.typeLevel = "High";
      this.isShowFooter = false;
      this.visisabledDetailSupplier = false;
      this.visisabledDetailFarmer = false;
      this.onChangeLevel("High");
    } else {
      this.isShowFooter = true;
      this.visisabledDetailSupplier = false;
      this.visisabledDetailFarmer = false;
      const configList = { ...this.dataListFarmInfo };
      this.dataListFarmInfo = { ...configList };
      this.dataListFarmInfo.userActive = null;
      this.getListFarm();
    }
  }

  openMapTerai(datalst, isClearMarker: boolean = true) {
    if(isClearMarker)
      this.deleteMarkers();
    setTimeout(() => {
      datalst.forEach((value, index) => {
        if (value.vido && value.kinhdo) {
          this.myMap(value.vido, value.kinhdo, value.churung, value, false, true);
        }
      });
      if(this.checkedGop){
        this.markerCluster = new MarkerClusterer(
          this.map,
          this.listMarker,
          {
            imagePath:
              "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            minimumClusterSize:5
          }
        );
      }else{
        this.markerCluster.setMap(null);
      }
    });
  }

  getSearchs(search) {
    this.dataSearch = [];
    this.reportService.getSearch(search).subscribe(
      (res: ResponseResult) => {
        if (res.statusCode === StatusCode.Status200OK) {
          this.dataSearch = res.data;
        } else {
          this.dataSearch = [];
        }
      },
      (error) => {
        this.globalsService.getError();
      }
    );
  }

  goSearch($event) {
    if ($event.length >= 3) {
      this.getSearchs($event);
    } else {
      if ($event === "") {
        this.dataSearch = [];
      }
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl("login");
  }

  onClickedOutside($event) {
    this.search = "";
    this.dataSearch = [];
  }

  modelChangedGd($event){
    if($event){
      this.markerCluster = new MarkerClusterer(
        this.map,
        this.listMarker,
        {
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
          minimumClusterSize:5
        }
      );
    }else{
      setTimeout(()=>{
        this.markerCluster.setMap(null);
      })
    }
  }

  goMarkerNear(){
    this.globalsService.showLoading();

    let curLat = this.dataDetail.listFarm[0].lat;
    let curLng =  this.dataDetail.listFarm[0].lng;
    this.checkedGop = false;
    let arrayNear = [];
    this.dataTerais.forEach((value, index) => {
      if (value.vido && value.kinhdo) {
        var distMeter = this.distance(value.vido, value.kinhdo, curLat,curLng);
        if(distMeter < 2){
          arrayNear.push(value);
        }
      }
    });
    this.globalsService.hideLoading();
    if(arrayNear.length > 0){
      this.openMapTerai(arrayNear, false);
    }else{
      this.globalsService.getErrorMessage("Không tìm thấy dữ liệu terra-i trong bán kính 2km");
    }
  }

  distance(lat1, lng1, lat2, lng2) {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var radlon1 = Math.PI * lng1 / 180;
    var radlon2 = Math.PI * lng2 / 180;
    var theta = lng1 - lng2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;

    //Get in in kilometers
    dist = dist * 1.609344;

    return dist;
}
}
