import { Component } from '@angular/core';
import { TranslateHelpersService } from './shares/helpers';
import { LanguageService } from './shares/services';

@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet><nz-spin id="hl-loading" class="loading-data" nzSimple nzSize="large"></nz-spin>'
})
export class AppComponent {
  constructor(translate: TranslateHelpersService) {
    translate.defaultLang();
  }

  ngOnInit(){
  }
}
