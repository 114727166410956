import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/shares/services';
import { TranslateHelpersService, GlobalsService } from 'src/app/shares/helpers';
import { StatusCode, ImageType } from 'src/app/shares/constants';
import { ResponseResult } from 'src/app/shares/interfaces';

@Component({
  selector: 'app-list-supplier',
  templateUrl: './list-supplier.component.html',
  styleUrls: ['./list-supplier.component.scss']
})
export class ListSupplierComponent implements OnInit {
  @Output() actionClicked = new EventEmitter<any>();
  listOfData: any = [];
  constructor( private userService: UserService,
    private translateHelpersService: TranslateHelpersService,
    private globals: GlobalsService) {
      this.listOfData = [];
    }

  ngOnInit() {
    this.getUser();
  }

  goClose(){
    this.actionClicked.emit({
      action: "Close",
      data: null
    });
  }

  goDetail(data){
    this.actionClicked.emit({
      action: "Detail",
      data: data
    });
  }

  getUser() {
    this.globals.showLoading();
    this.userService.getByUserGroupId(11, 0).subscribe((res: ResponseResult) => {
      this.globals.hideLoading();
      if (res.statusCode === StatusCode.Status200OK) {
        this.listOfData = res.data.items;
      } else {
        this.listOfData = [];
      }
    }, error => {
      this.globals.getError();
      this.globals.hideLoading();
    });
  }

  getImage(detail){
    return this.globals.getAvartaUser(detail.userId);
  }
}
