import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-map-new',
  templateUrl: './list-map-new.component.html',
  styleUrls: ['./list-map-new.component.scss']
})
export class ListMapNewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
