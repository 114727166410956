import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Local } from '../constants';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ResponseResult } from '../interfaces';
import { retry, catchError } from 'rxjs/operators';
import { TranslateHelpersService } from '../helpers';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private httpOptions: any = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'lang': this.translate.getLocalLang(),
            'parentId': '0',
            'Authorization': 'Bearer ' + localStorage.getItem(Local.TOKEN)
        })
    };

    public base_url: string = environment.APIEndpoint;

    constructor(private http: HttpClient, private translate: TranslateHelpersService) { }

    changeHeader(lang: string = '', parentId: string = '0') {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'lang': lang === '' ? this.translate.getLocalLang() : lang,
                'parent': parentId + '',
                'Authorization': 'Bearer ' + localStorage.getItem(Local.TOKEN)
            })
        };
    }

    getAll(url: string, hasParam: boolean, pageSize: number, pageIndex: number, lang: string = ''): Observable<ResponseResult> {
        if (hasParam) {
            url = url + '&';
        } else { url = url + '?'; }
        this.changeHeader(lang);
        return this.http.get<ResponseResult>(`${this.base_url}${url}pageSize=${pageSize}&pageIndex=${pageIndex}`, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    getById(url: string, lang: string = ''): Observable<ResponseResult> {
        this.changeHeader(lang);
        return this.http.get<ResponseResult>(`${this.base_url}${url}`, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    createData(url: string, model: any, lang: string = '', parentId: string = '0'): Observable<ResponseResult> {
        this.changeHeader(lang, parentId);
        return this.http.post<ResponseResult>(`${this.base_url}${url}`, JSON.stringify(model), this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    updateData(url: string, model: any, lang: string = '', parentId: string = '0'): Observable<ResponseResult> {
        this.changeHeader(lang, parentId);
        return this.http.put<ResponseResult>(`${this.base_url}${url}`, JSON.stringify(model), this.httpOptions).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    deleteData(url: string): Observable<ResponseResult> {
        return this.http.delete<ResponseResult>(`${this.base_url}${url}`, this.httpOptions).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    private handleError(error: any): Promise<any> {
        console.log('An error occurred', error.message); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
