import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { UserGroupDetailComponent } from './user-groups/user-group-detail/user-group-detail.component';
import { UserGroupListComponent } from './user-groups/user-group-list/user-group-list.component';
import { UserDataDetailComponent } from './user-datas/user-data-detail/user-data-detail.component';
import { UserDataListComponent } from './user-datas/user-data-list/user-data-list.component';
import { UserRoleDetailComponent } from './user-roles/user-role-detail/user-role-detail.component';
import { UserRoleListComponent } from './user-roles/user-role-list/user-role-list.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    UserGroupDetailComponent,
    UserGroupListComponent,
    UserDataDetailComponent,
    UserDataListComponent,
    UserRoleDetailComponent,
    UserRoleListComponent
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    TranslateModule,
  ],
  exports: [
    UserDataDetailComponent
  ]
})
export class UsersModule { }
