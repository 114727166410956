import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalsService } from 'src/app/shares/helpers';

@Component({
  selector: 'app-upload-filed',
  templateUrl: './upload-filed.component.html',
  styleUrls: ['./upload-filed.component.scss']
})
export class UploadFiledComponent implements OnInit {
  @Input() image: any;
  @Input() config: any;

  @Output() resultImage = new EventEmitter<any>();

  constructor(private globals: GlobalsService) { }

  ngOnInit() {
  }

  changeListener($event): void {
    if(!this.validateSize($event.target)){
      this.globals.getErrorMessage("Kích thước hình ảnh phải nhỏ hơn 1MB");
      return;
    }
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result + '';
      this.resultImage.emit({ image: myReader.result + '' });
    };
    myReader.readAsDataURL(file);
  }

  validateSize(inputValue: any) {
    let fileSize = inputValue.files[0].size / 1024 / 1024; // in MB
    if (fileSize > 1) {
     return false;
    }
    return true;
  }

}
